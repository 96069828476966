<template>
    <v-container class="ma-0 pa-0 login_background" style="background-color: #1b1b1b; width: 100%; height: 100%;" fluid>
        <v-img :src=background_img style="height: 100%" cover>
            <v-row class="ma-0 pa-0" style="height: 100%; background-color: #1b1b1b90;">
                <v-row class="ma-0 pa-0" style="height: 65%;" justify="space-around">
                    <v-col cols="6" align-self="center">
                        <v-card outlined class="pa-6 pt-12 pb-12 ma-0 login_card" style="background-color: #1b1b1b;">
                            <v-form v-if="!token_loading" class="ma-0 pa-4">
                                <v-row class="ma-0 pa-0">
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto">
                                        <v-img :src=main_logo_inverterd contain max-width="250px"></v-img>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                                <v-row class="ma-0 pa-0">
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto">
                                        <span class="loginFormTextSubtitle">Welcome to Docma Enterprise</span>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                </v-row>
                                <v-row class="mt-6">
                                    <v-col cols="12">
                                        <v-row class="ma-0 pa-0">
                                            <v-spacer></v-spacer>
                                            <v-col class="ma-0 pa-0" cols="10" style="width: 100%">
                                                <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="white" @click="login">Log In / Sign Up</v-btn>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-form>
                            <v-progress-circular indeterminate size="50" v-else></v-progress-circular>
                        </v-card>
                    </v-col>
                </v-row>
            </v-row>
        </v-img>
        <!-- <v-row class="ma-0 pa-0" style="background-color: #1b1b1b; width: 100%; height: 100%;">
            <v-spacer></v-spacer>
            <v-col cols="auto" align-self="center">
                <v-row class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0">
                        <v-img :src=hex_img contain class="login_top_hex"></v-img>
                    </v-col>
                    <v-spacer></v-spacer>
                </v-row>
                <v-card outlined class="pa-0 ma-0 login_card">
                    <v-form v-if="!token_loading" class="ma-0 pa-4">
                        <v-row class="ma-0 pa-0">
                            <v-spacer></v-spacer>
                            <v-col cols="auto">
                                <v-img :src=main_logo_inverterd contain max-width="150px"></v-img>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-row class="ma-0 pa-0">
                                    <v-col class="ma-0 pa-0" cols="auto" style="width: 100%">
                                        <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="primary" @click="login">Log In</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-progress-circular indeterminate size="50" v-else></v-progress-circular>
                </v-card>
                <v-row class="ma-0 pa-0">
                    <v-spacer></v-spacer>
                    <v-col class="ma-0 pa-0">
                        <v-img :src=background_img contain class="login_bottom_hex"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-spacer></v-spacer>
        </v-row> -->
    </v-container>
</template>

<script>
import docma_logo from "@/assets/docma-logo-white-red.svg";
import hex from "@/assets/footer-hex.svg";
import background from "@/assets/login_background.jpg"
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   sendPasswordResetEmail,
// } from "firebase/auth";

export default {
  name: "Login",
    data() {
        return {
          // email: "",
          // password: "",
            token_loading: false,
            main_logo_inverterd: docma_logo,
            hex_img: hex,
            background_img: background,

            showpass: false,

            // login_error: false,
            // login_success: false,
            // password_reset_success: false,
            // password_reset_input_error: false,
            // password_reset_error: false,
    };
  },
  mounted() {
    if(this.$auth.isAuthenticated) {
       this.$router.push("/reporting");
    }
    },
    methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
            });
        },
    // login: async function(){
    //     try{
    //         await this.firebase_login_auth(this.email, this.password);
    //         this.login_success = true;
    //         setTimeout( () => this.login_success = false, 5000)
    //         this.$router.replace('audience');
    //     }
    //     catch(err){
    //         this.login_error = true;
    //         setTimeout( () => this.login_error = false, 5000)
    //         this.password = '';
    //     }
    // },
    // firebase_login_auth: function (email, password) {
    //         const auth = getAuth();
    //         return new Promise((resolve, reject) => {
    //     signInWithEmailAndPassword(auth, email, password)
    //       .then(() => {
    //                     resolve(true);
    //       })
    //       .catch(() => {
    //                     reject(false);
    //       });
    //         });
    //     },
    // password_reset() {
    //   if (this.valid_email()) {
    //             const auth = getAuth();
    //             sendPasswordResetEmail(auth, this.email)
    //             .then(() => {
    //                 this.password_reset_success = true;
    //         setTimeout(() => (this.password_reset_success = false), 5000);
    //             })
    //             .catch(() => {
    //                 this.password_reset_error = true;
    //         setTimeout(() => (this.password_reset_error = false), 5000);
    //             });
    //   } else {
    //             this.password_reset_input_error = true;
    //     setTimeout(() => (this.password_reset_input_error = false), 5000);
    //         }
    //     },
    // valid_email() {
    //         let re = /\S+@\S+\.\S+/;
    //         return re.test(this.email);
    //     },
    // clear_alert() {
    //   setTimeout(() => (this.password_reset_input_error = false), 5000);
    // },
        },
};
</script>

<style scoped>
    .login_background{
        background-image: url('~@/assets/login_background.jpg') no-repeat center center fixed; 
        background-size: cover;
        height: 100vh;
    }
    .loginFormTextStyle {
        font-family: "Poppins" !important;
        width: 90%;
        color: white;
    }
    .loginFormTextSubtitle {
        font-family: "Poppins" !important;
        font-family: 200;
        font-size: 1.5em;
        width: 90%;
        color: white;
    }
    .loginFormAlerts{
        font-family: 'Poppins' !important;
        position: absolute; 
        width: 100%;
        z-index: 4;
    }
    .login_card {
        min-width: 300px; 
        max-width: 50vw; 
        z-index: 3;
    }
    .login_top_hex {
        transform: translateY(30%) translateX(-20%);
        z-index: 0;
        max-width: 150px;
    }
    .login_bottom_hex {
        transform: translateY(-30%) translateX(30%) rotate(240deg);
        z-index: 0;
        max-width: 150px;
    }
</style>