<template>
    <v-card style="height: 100%; width: 100%;">
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-if="data == null" align="center" justify="center">
            <v-col cols="auto">
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else-if="data == -1" align="center" justify="center">
            <v-col cols="auto">
                <span class="no-data-available-title" >No Data Available</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else>
            <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0">
                    <v-col cols="auto">
                        <span class="highlight_card_title">{{ data['date'] }}</span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <!-- <v-col cols="auto">
                        <v-icon color="black">mdi-rotate</v-icon>
                    </v-col> -->
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_stat">{{ data['reach'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }} / {{ data['frequency'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                            </v-col>
                            <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_title">Reach/Frq.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- <v-col cols="4">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_stat">{{ data['frequency'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                            </v-col>
                            <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_title">Frq.</span>
                            </v-col>
                        </v-row>
                    </v-col> -->
                    <v-col cols="6">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_stat">{{ data['device_reach'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }} / {{ data['frequency'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                            </v-col>
                            <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_title">D. Reach / Frq.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="ma-0 pa-0">
                    <v-col cols="4">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_stat">-</span>
                            </v-col>
                            <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_title">GRPs</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_stat">{{ data['impressions'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                            </v-col>
                            <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_title">Impr.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="4">
                        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_stat">-</span>
                            </v-col>
                            <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                            <v-col class="ma-0 pa-0" cols="12" align="center">
                                <span class="highlight_title">Tgt Pop.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'ReportingHighlights',
    props: [
        'data'
    ],
    mounted(){
    },
    computed: {
    },
    data: () => ({
        selected_campaign_highlights: {
                'impressions': 0, 'reach': 0, 'frequency': 0
        }
    }),
    methods: {
    },
    watch: {
    }
}
</script>

<style scoped>
    .highlight_card_title{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.1em;
    }
    .highlight_stat{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.4em;
    }
    .highlight_title{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.8em;
    }
    .no-data-available-title{
        font-family: 'Poppins';
        font-weight: 200;
        font-size: 1.2em;
    }
</style>