import Vue from 'vue'
import Router from 'vue-router'
// import firebase from 'firebase'

import Home from '../views/Planning.vue'
import Login from '../views/Login.vue'
// import data_viz from '../components/data_viz'
// import ServerMaintenance from '../views/ServerMaintenance'

import { authGuard } from '../auth/authGuard';
import Planning from '../views/Planning.vue';
import Reporting from '../views/Reporting.vue';

Vue.use(Router)

const router = new Router({
    routes : [
        {
          path: '*',
          redirect: '/login'
        },
        {
          path: '/',
          redirect: '/login'
        },
        {
            path: '/planning',
            name: 'Planning',
            component: Planning,
            meta: {
                requiresAuth: true,
                title: 'Docma',
                hideNavbar: false,
            },
            beforeEnter: authGuard
        },
        {
            path: '/reporting',
                name: 'Home',
                component: Reporting,
                meta: {
                    requiresAuth: true,
                    title: 'Docma',
                    hideNavbar: false,
                },
                beforeEnter: authGuard
            },
        {
          path: '/login',
          name: 'Login',
        //   redirect: '/ServerMaintenance',
          component: Login,
          meta: {
              title: 'Docma',
              hideNavbar: true
          }
        }
    ]
});

// router.beforeEach((to,from,next) => {
//     // const currentUser = firebase.auth().currentUser;
//     const currentUser = Vue.prototype.$token.get_val();
//     const requiresAuth = to.matched.some(record=>record.meta.requiresAuth);

//     document.title = to.meta.title;

//     if(requiresAuth && !currentUser){
//         next('Login');
//     }
//     else{
//         next();
//     }
// });

export default router;
