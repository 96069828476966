<template>
    <v-card style="height: 100%; width: 100%;" >
        <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col cols="auto">
                <span class="filter-title">{{chart_title}}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: calc(100% - 60px); width: 100%;" v-if="data == null" align="center" justify="center">
            <v-col cols="auto">
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: calc(100% - 60px); width: 100%;" v-else-if="data == -1" align="center" justify="center">
            <v-col cols="auto">
                <span class="no-data-available-title" >No Data Available</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: calc(100% - 60px); width: 100%;" v-else>
            <v-col cols="12">
                <GChart
                style="height: 100%; width: 100%;"
                :settings="{ packages: ['corechart', 'table'] }"
                type="ColumnChart"
                :options="chartOptions"
                :data="chart_data"></GChart>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'ReportingFrequencyDistribution',
    props: [
        'data',
        'stat',
        'type'
    ],
    mounted(){
    },
    computed: {
    },
    watch: {
        data: {
          handler: function(){
              this.reset_data();
          },
          immediate: true
        },
        stat: {
          handler: function(){
              this.reset_data();
          },
          immediate: false
        },
        type: {
          handler: function(){
              this.reset_data();
          },
          immediate: false
        }
    },
    data() {
        return {
            chartOptions: {
                colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
                vAxes: { 0:{titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 16, italic: false}, textStyle:{fontName: 'Poppins', fontSize: 12}}},
                vAxis: { viewWindow:{min: 0}, minValue: 0, viewWindowMode: 'explicit', baselineColor: '#000000', gridlines:{count: 0}, minorGridlines:{count: 0}},
                hAxis: {viewWindowMode: 'explicit', viewWindow:{min: 0}, minValue: 0, format:'date', slantedText: true, slantedTextAngle: -15, baselineColor: '#000000', textStyle:{fontName: 'Poppins', fontSize: 12}, titleTextStyle:{fontName: 'Poppins', fontSize: 16, italic: false}, gridlines:{count: 0}, minorGridlines:{count: 0}},
                curveType: 'function',
                legend: { position: 'none'},
                chartArea: {left: '10%', top: '2.5%', width: '80%', height: '80%'},
                pointSize: 5,
                tooltip: {isHtml: true},
                backgroundColor: 'none'
            },

            chart_title: "",
            chart_data: null
        }
    },
    methods: {
        reset_data: function(){
            let stat_idx = 5;
            let chart_title = "Pacing Frequency"
            if(this.stat === 'cumulative'){
                stat_idx += 4;
                chart_title = chart_title.replace('Pacing', 'Cumulative');
            }
            if(this.type == 'device'){
                stat_idx -= 1;
                chart_title = chart_title.replace('Frequency', 'Device Frequency');
            }

            this.chart_title = chart_title;

            if(this.data != null && this.data != -1){
                let chart_data = [];
                let idx = 0;
                for(let r of this.data){
                    if(idx == 0){
                        chart_data.push([r[0], r[stat_idx]]);
                    }
                    else{
                        chart_data.push([r[0], r[stat_idx]]);
                    }
                    idx += 1;
                }
                this.chart_data = chart_data;
            }
      },
    }
}
</script>

<style scoped>
    .highlight_card_title{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.1em;
    }
    .highlight_stat{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.4em;
    }
    .highlight_title{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.8em;
    }
    .no-data-available-title{
        font-family: 'Poppins';
        font-weight: 200;
        font-size: 1.2em;
    }
    .filter-title{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.2em;
        line-height: 36px;
    }
</style>