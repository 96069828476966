import axios from 'axios'

// const api_dev = 'https://staging.api.docmasweetspot.ca';
const api_live = 'https://staging.enterprise.api.docmasweetspot.ca'
// const api_live = 'http://127.0.0.1:5001'

function available_configurations(token, data){
    let api_url = api_live+'/sweetspot/reporting/available_campaigns';
    return axios_post_call(api_url,data,token);
}

function campaign_info(token, data){
    let api_url = api_live+'/sweetspot/reporting/campaign_info';
    return axios_post_call(api_url,data,token);
}


function configuration_data(token, data){
    let api_url = api_live+'/sweetspot/reporting/campaign_data';
    return axios_post_call(api_url,data,token);
}

/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers:{Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {available_configurations, configuration_data, campaign_info}