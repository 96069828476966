<template>
  <v-card style="height: 100%; width: 100%;">
      <v-row class="ma-0 pa-0" align="center" justify="center">
        <v-col cols="auto">
          <span class="filter-title">{{chart_title}}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" cols="auto">
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-chip :disabled="data == null || data == -1" v-on="on" class="data_breakdown_chip" :outlined="statistic_type !== 'reach_curve'" label color="black" @click="statistic_type = 'reach_curve';"><v-icon>mdi-chart-bell-curve-cumulative</v-icon></v-chip>
                      </template>
                      <TooltipDescription title="Reach Curve"></TooltipDescription>
                  </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-chip disabled v-on="on" class="data_breakdown_chip" :outlined="statistic_type !== 'map'" label color="black" @click="statistic_type = 'map';"><v-icon>mdi-map</v-icon></v-chip>
                      </template>
                      <TooltipDescription title="Map Overview"></TooltipDescription>
                  </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" cols="auto">
                  <v-tooltip top>
                      <template v-slot:activator="{ on }">
                          <v-chip :disabled="data == null || data == -1" v-on="on" class="data_breakdown_chip" :outlined="statistic_type !== 'table'" label color="black" @click="statistic_type = 'table';"><v-icon>mdi-table</v-icon></v-chip>
                      </template>
                      <TooltipDescription title="Table Overview"></TooltipDescription>
                  </v-tooltip>
              </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="height: calc(100% - 60px); width: 100%;" v-if="data == null" align="center" justify="center">
          <v-col cols="auto">
              <v-progress-circular indeterminate size="100"></v-progress-circular>
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="height: calc(100% - 60px); width: 100%;" v-else-if="data == -1" align="center" justify="center">
          <v-col cols="auto">
              <span class="no-data-available-title" >No Data Available</span>
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="height: calc(100% - 60px); width: 100%;" v-else>
        <v-col cols="12" class="" v-if="statistic_type === 'reach_curve'">
          <GChart
          style="height: 100%; width: 100%;"
          :settings="{ packages: ['corechart', 'table'] }"
          type="LineChart"
          :options="chartOptions"
          :data="chart_data"></GChart>
        </v-col>
        <v-col cols="12" class="pt-0" v-else-if="statistic_type === 'table'">
          <DataLevelTable 
            :tableData="table_data" :tableHeader="table_header" :selectedLevels="[]" :numericColumns="['impr','reach','freq']" :currencyColumns="[]" :percentageColumns="[]"></DataLevelTable>
        </v-col>
      </v-row>
  </v-card>
</template>

<script> 
  import DataLevelTable from './DataLevelTable.vue';
  import TooltipDescription from './TooltipDescription.vue';
  export default {
    name: "ReportingReachCurve",
    props: [
      'data',
      'stat',
      'type'
    ],
    components: {
      TooltipDescription,
      DataLevelTable
    },
    data () {
      return {
        chartOptions: {
          colors: ['#0079c1','#AD343E','#F2AF29','#FF3CC7','#00E5E8','#628B48', '#7D3AC1', '#DE542C', '#820401', '#7FD62F'],
          vAxes: { 0:{ titleTextStyle:{fontName: this.$configuration.get_config_param('graph_axis_title_font'), fontSize: 16, italic: false}, textStyle:{fontName: 'Poppins', fontSize: 12}}},
          vAxis: { viewWindow:{min: 0}, minValue: 0, viewWindowMode: 'explicit', baselineColor: '#000000', gridlines:{count: 0}, minorGridlines:{count: 0}},
          hAxis: {viewWindowMode: 'explicit', viewWindow:{min: 0}, minValue: 0, format:'date', slantedText: true, slantedTextAngle: -15, baselineColor: '#000000', textStyle:{fontName: 'Poppins', fontSize: 12}, titleTextStyle:{fontName: 'Poppins', fontSize: 16, italic: false}, gridlines:{count: 0}, minorGridlines:{count: 0}},
          curveType: 'function',
          legend: { position: 'none'},
          chartArea: {left: '12%', top: '2.5%', width: '80%', height: '87.5%'},
          pointSize: 5,
          tooltip: {isHtml: true},
          backgroundColor: 'none'
        },

        chart_title: "",
        chart_data: null,
        table_data: null,
        table_header: null,

        statistic_type: 'reach_curve'
      }
    },
    mounted(){
    },
    watch: {
        data: {
          handler: function(){
              this.reset_data();
          },
          immediate: true
        },
        stat: {
          handler: function(){
              this.reset_data();
          },
          immediate: false
        },
        type: {
          handler: function(){
              this.reset_data();
          },
          immediate: false
        }
    },
    methods: {
      reset_data: function(){
        let stat_idx = 3;
        let chart_title = "Pacing, Unique Reach"
        if(this.stat === 'cumulative'){
          stat_idx += 4;
          chart_title = 'Cumulative, Unique Reach';
        }
        if(this.type == 'device'){
          stat_idx -= 1;
          chart_title = chart_title.replace('Unique', 'Device');
        }
        this.chart_title = chart_title;

        if(this.data != null && this.data != -1){
          let chart_data = [];
          let tbl_data = [];
          let tbl_header = [{'text': 'Date', 'value': 'date'}, {'text': 'Reach', 'value': 'reach'}, {'text': 'Impressions', 'value': 'impr'}, {'text': 'Frequency', 'value': 'freq'}];
          let idx = 0;
          console.log(this.data)
          for(let r of this.data){
            if(idx == 0){
              chart_data.push([r[0], r[stat_idx], {"type": "string","role": "tooltip","p":{"html":true}}]);
            }
            else{
              chart_data.push([r[0], r[stat_idx], this.toolTip((r[0] + " - " + r[1]), r[2], r[3], r[7], r[9]) ]);
              
              let tbl_data_tmp = {
                'date': (r[0] + " - " + r[1]),
                'reach': r[stat_idx],
                'impr': r[stat_idx] * r[stat_idx + 2],
                'freq': r[stat_idx + 2]
              };
              // for(let jdx = 0; jdx < r.length; jdx += 1){
              //   tbl_data_tmp[tbl_header[jdx]['value']] = r[jdx];
              // }
              tbl_data.push(tbl_data_tmp);
            }
            idx += 1;
          }
          this.chart_data = chart_data;
          this.table_data = tbl_data;
          this.table_header = tbl_header;
        }
      },
      toolTip: function(configurationName, pacing_reach, pacing_device_reach, cumulative_reach, cumulative_freq){
        let formatter = new Intl.NumberFormat('en-US', {style:'currency', currency: 'USD'});
        // budget = formatter.format(budget);
        let tooltip_html = '<div style="padding: 5px">';
        tooltip_html += '<table style="max-width: 15vw; word-wrap: break-word">';
        tooltip_html += '<tr><th class="tooltip_title" style="text-align: center">'+configurationName+'</th></tr>';
        tooltip_html += '<tr><td class="tooltip_budg" style="text-align: center">Pacing Reach: '+pacing_reach+'</td></tr>';
        tooltip_html += '<tr><td class="tooltip_budg" style="text-align: center">Pacing Device Reach: '+pacing_device_reach+'</td></tr>';
        // if(this.absoluteReach){
        tooltip_html += '<tr><td class="tooltip_value" style="text-align: center">' + cumulative_reach.toLocaleString("en-US", {notation: 'compact'}) + '/' + cumulative_freq.toLocaleString("en-US", {notation: 'compact', minimumFractionDigits: 1, maximumFractionDigits: 1}) + '</td></tr>';
        // }
        // else {
        //     unique_reach = (unique_reach * 100).toFixed(2);
        //     tooltip_html += '<tr><td class="tooltip_value" style="text-align: center">' + unique_reach + '%</td></tr>';
        // }
        tooltip_html += '</table>';
        tooltip_html += '</div>';
        return tooltip_html;
      }
    }
  }
</script>

<style scoped>
  .no-data-available-title{
        font-family: 'Poppins';
        font-weight: 200;
        font-size: 1.2em;
    }
  .filter-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2em;
    line-height: 36px;
  }
  .v-chip.v-chip--outlined.v-chip.v-chip{
        color: black !important;
    }
  .data_breakdown_chip {
      font-family: Poppins;
      color: white !important;
      font-weight: 600;
      width: 60px;
      justify-content: center;
      margin-left: 1px;
      margin-right: 1px;
  }
</style>
