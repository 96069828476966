<template>
    <v-card style="height: 100%; width: 100%;">
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-if="data == null" align="center" justify="center">
            <v-col cols="auto">
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else-if="data == -1" align="center" justify="center">
            <v-col cols="auto">
                <span class="no-data-available-title" >No Data Available</span>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else>
            <v-col cols="12">
                <DataLevelTable :tableData="data['campaign_table']['data']" :tableHeader="data['campaign_table']['header']" :selectedLevels="[]" :numericColumns="['campaign_impressions','campaign_reach','campaign_frequency','campaign_device_reach','campaign_device_frequency']" :currencyColumns="[]" :percentageColumns="[]" :short="true"
                                :tableRowClickFunction="rowSelected"></DataLevelTable>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import DataLevelTable from './DataLevelTable.vue';

export default {
    name: 'ReportingPortfolio',
    props: [
        'data'
    ],
    components: {
        DataLevelTable
    },
    mounted(){
    },
    computed: {
    },
    data: () => ({
        selected_campaign_highlights: {
                'impressions': 0, 'reach': 0, 'frequency': 0
        }
    }),
    methods: {
        rowSelected: function(row){
            console.log('Selected Row', row)
            let body = {
                'campaign_id': row['campaign_id'],
                'time_grouping': 'daily'
            };
            let campaign = {
                'campaign_id': row['campaign_id'],
                'start_date': row['campaign_start_date'],
                'end_date': row['campaign_end_date']
            }
            this.$emit('switch_campaign',body,campaign);
        }
    },
    watch: {
    }
}
</script>

<style scoped>
    .highlight_card_title{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.1em;
    }
    .highlight_stat{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.4em;
    }
    .highlight_title{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.8em;
    }
    .no-data-available-title{
        font-family: 'Poppins';
        font-weight: 200;
        font-size: 1.2em;
    }
</style>