<template>
    <v-container style="max-height: 100%; height:100%" fluid>
        <v-layout text-center wrap style="height: 100%; width: 100%">
            <v-row style="height: 100%; width: 100%" v-if="!initial_system_load" align="center">
                <v-col sm="12">
                    <v-progress-circular v-model="initial_load_status" size="300" width="10" color="primary" >
                        <span class="load_screen_text">{{initial_load_text_status}}</span>
                    </v-progress-circular>
                </v-col>
            </v-row>
            <v-navigation-drawer
                    app
                    v-model="dataSelectorDrawer"
                    absolute
                    temporary
                    left
                    :width="drawerWidth"
                    height="100%"
                    style="overflow-y: hidden;"
                    v-if="!configTreeLoading">
                <configuration-drawer :globalUpdate="globalUpdate" :inputBudget="budget" :configurationTree="configurationTree" :configuration="configuration" :data="data" :visibleBudgToSave="cur_visible_budgets_to_save" :loading="configTreeLoading" v-on:updatedBudget="updateBudget" v-on:runConfigurations="runConfigurations" v-on:deleteConfigurations="deleteConfigurations" v-on:undoDelete="undoDelete" v-on:updateCPM="updateCPM" v-on:closeScenarioClipboard="updateDataSelectorDrawerState(false)" v-on:widthUpdate="update_drawer_width"></configuration-drawer>
            </v-navigation-drawer>
            <v-col class="pa-0" style="max-width: 50px; width: 50px;" v-if="initial_system_load">
                <v-row v-if="tab_view == 0 || tab_view == 1" class="pa-0 ma-0" style="position: absolute; left: 0; top: 0; width: 3vw; height: 100%; border-top-right-radius: 20px; border-bottom-right-radius: 20px; background-color: #A0ACAD60 !important;" wrap align="center" @click="updateDataSelectorDrawerState">
                    <v-col sm="12" class="pa-0 ma-0">
                        <v-icon @click="updateDataSelectorDrawerState">mdi-chevron-double-right</v-icon>
                    </v-col>
                </v-row>
            </v-col>
            <v-col class="pa-0 ma-0" style="max-width: 95vw; width: 95vw;" v-if="initial_system_load">
                <v-row class="pa-0 ma-0" style="width: 100%; height: 100%" align="center">
                    <v-col class="ma-0" sm="7" style="max-height:100%; height:100%;">
                        <unique-reach :targetPop="targetPop" :data="data" :dataManager="dataManager" :loading="loading_new_data" :budget="budget" :get_pdf="get_pdf_docs" :get_pptx="get_pptx_docs" :omni_channel="omniChannelActive" :omni_enabled="omniChannelEnabled" v-on:childToParent="updateBudget" v-on:pdf_downloaded="pdf_segment" v-on:pptx_downloaded="pptx_segment" v-on:updatedBudg="updateVisibleBudg" v-on:build_omni_channel="set_omni_channel" v-on:optimize_scenario="optimize_scenario"></unique-reach>
                    </v-col>
                    <v-col class="ma-0" sm="5" style="max-height:100%; height:100%;">
                        <v-row class="ma-0 pa-0" style="height:100%">
                            <v-col sm="12" class="pt-0" style="max-height: 60%; height:60%">
                                <frequency-distrubtion :selected_budget=budget :data="data" :loading="loading_new_data" :dataManager="dataManager" :get_pdf="get_pdf_docs" v-on:pdf_downloaded="pdf_segment" :get_pptx="get_pptx_docs" v-on:pptx_downloaded="pptx_segment"></frequency-distrubtion>
                            </v-col>
                            <v-col sm="12" class="pb-0" style="max-height: 40%; height:40%">
                                <highlights :dataManager="dataManager" :runConfigurationsList="runConfigurationsList" :loading="loading_new_data" :budget="budget"></highlights>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-layout>
    </v-container>
</template>
<script>
import uniqueReach from '../components/uniqueReachCurvev4'
import frequencyDistrubtion from '../components/frequencyDistrubution'
import highlights from '../components/Highlightsv2'
import configurationDrawer from '../components/configurationDrawer'

import Data from '../javascript/data'
import SweetSpotConfiguration from '../javascript/sweetSpotConfiguration'
import SweetSpotDataManager from '../javascript/sweetSpotDataManager'
import SweetSpotConfigurationManager from '../javascript/sweetSpotConfigurationManager'
import {Tree} from '../javascript/configurationTree'

import apiCaller from '../javascript/api_caller'

export default {
    name: 'planningPage',
    components: {
        uniqueReach,
        frequencyDistrubtion,
        highlights,
        configurationDrawer
    },
    props: [
        'download',
        'download_type',
        'download_name',
        'pricing_scheme'
    ],
    data(){
        return {
            initial_tree_loaded: false,
            initial_config_loaded: false,
            initial_data_loaded: false,
            initial_system_load: true,
            initial_load_status: 30,
            initial_load_text_status: "Loading Scenarios",
            loading_new_scenarios: true,
            loading_percentage: 0,
            current_scenario_loading: '',
            budget: 0,
            overlay: false,
            dataSelectorDrawer: false,
            uniqueReachMax: 0,
            freqDistMax: 0,
            onTargetReach: 0,
            avgFrequency: 0,
            targetPop: 0,
            grps: 0,
            configurationTree: null,
            configTreeLoading: true,
            data: null,
            loading_new_data: false,
            configuration: null,
            sweet_spot_api_token: null,
            cpm: 20,
            dataManager: null,
            runConfigurationsManager: null,
            runConfigurationsList: [],
            budgetPoints: [],
            globalUpdate: null,
            get_pdf_docs: false,
            num_pdf_segments: 2,
            num_pdf_segments_collected: 0,
            num_pptx_segments: 2,
            num_pptx_segments_collected: 0,
            pdf_doc: null,
            pptx_doc:null,
            get_pptx_docs: false,
            tab_view: 0,
            viewable: false,
            drawerWidth: '50vw',
            omniChannelActive: false,
            omniChannelEnabled: false,
            cur_visible_budgets_to_save: [],
            pptxBuilder: null,
            pdfBuilder: null
        }
    },
    watch: {

    },
    mounted(){
        // this.apiToken = this.$token.get_val();

        // this.buildTree();
        // this.data = new Data();
        // this.configuration = new SweetSpotConfiguration();
        // this.dataManager = new SweetSpotDataManager();
        // this.runConfigurationsManager = new SweetSpotConfigurationManager();
    },
    updated(){

    },
    methods: {
        update_drawer_width: function(drawerWidth){
            this.drawerWidth = drawerWidth;
        },
        runConfigurations: async function(run_info){
            if(!this.initial_data_loaded){
                await this.sleep(1000);
                this.update_load_status("configuration");
            }
            this.loading_new_scenarios = true;
            let apiData = null;
            let targPop = 0;
            this.loading_new_data = true;
            this.dataSelectorDrawer = false;
            let configs = run_info['run'];
            let visibleConfigs = run_info['visible'];
            let visConfigIds = [];
            let num_configs = Object.keys(configs).length;
            this.number_of_scenarios_to_load = num_configs
            if(num_configs <= 0 ){
                await this.sleep(500);
            }
            let cur_scenario = 1;
            for(let configId of Object.keys(configs)){
                this.current_scenario_loading = configs[configId].get_configuration_name();
                var t0 = performance.now();
                apiCaller.add_run(this.apiToken);
                if(this.pricing_scheme == null){
                    apiData = await apiCaller.process_config(this.apiToken,configs[configId].get_configuration()).catch((e)=>{
                        console.log(e)
                        return false;
                    });
                }
                else if(this.pricing_scheme == false){
                    apiData = await apiCaller.process_config_national(this.apiToken,configs[configId].get_configuration()).catch((e)=>{
                        console.log(e)
                        return false;
                    });
                }
                else if(this.pricing_scheme == true){
                    apiData = await apiCaller.process_config_local(this.apiToken,configs[configId].get_configuration()).catch((e)=>{
                        console.log(e)
                        return false;
                    });
                }
                var t1 = performance.now();
                if(apiData == false){
                    continue;
                }
                console.log('Time to run: ' + ((t1 - t0)/1000) + ' seconds');
                if(this.dataManager.hasConfigBeenRun(configId)){
                    this.dataManager.updateDataObject(configId,apiData['data']);
                    this.runConfigurationsManager.replaceConfiguration(configId, configs[configId].get_configuration());
                }
                else {
                    this.dataManager.addNewDataObject(configId, apiData['data']);
                    this.runConfigurationsManager.addNewConfigurationFromConfigurationAt(configs[configId].get_configuration(), configId);
                }
                if(run_info.hasOwnProperty('visible_budgets')){
                    this.dataManager.setVisibleBudget(configId,run_info['visible_budgets'][(cur_scenario - 1)]);
                }
                targPop = await apiCaller.target_population(this.apiToken,configs[configId].get_configuration());
                this.dataManager.setTargetPop(configId,targPop['targetPop']);
                this.dataManager.setCPM(configId,apiData['cpm']);
                visConfigIds.push(configId);
                this.loading_percentage = (cur_scenario / num_configs) * 100;
                cur_scenario ++;
            }
            this.dataManager.setVisibleConfigurations(visibleConfigs);
            this.budgetPoints = this.dataManager.getBudgetPoints();
            this.budget = this.budgetPoints[1];
            this.runConfigurationsList = this.runConfigurationsManager.getConfigurationList();
            this.omniChannelActive = this.runConfigurationsManager.are_configurations_omni_channel_compatible(this.dataManager.get_visible_ids());
            this.omniChannelEnabled = false;
            this.targetPop = this.dataManager.getTargetPopGreatest();
            this.loading_new_data = false;
            this.loading_new_scenarios = false;
            this.loading_percentage = 0;
            this.current_scenario_loading = '';
            if(!this.initial_data_loaded){
                await this.sleep(1000);
                this.update_load_status("data");
            }
        },
        deleteConfigurations: async function(ids){
            this.loading_new_data = true;
            this.dataManager.deleteDataObjectList(ids);
            this.runConfigurationsManager.deleteConfigurationList(ids);
            await this.sleep(500);
            this.loading_new_data = false;
        },
        undoDelete: function(idList){
          this.runConfigurationsManager.undoRecentlyDeletedFromIdList(idList);
          this.dataManager.undoRecentlyDeleted();
        },
        updateGlobalConfiguration: function(value){
            this.globalUpdate = value;
        },
        updateBudget: function(value){
            this.budget = value;
            // this.onTargetReach = this.data.get_on_target_reach(this.budget);
            // this.avgFrequency = this.data.get_avg_frequency_at_budget(this.budget);
            // this.grps = this.data.get_grps(this.budget,this.targetPop);
        },
        updateCPM: function(value){
          this.cpm = value;
        },
        updateOverlay: function (value) {
            this.overlay = value;
        },
        updateDataSelectorDrawerState: function(value){
            this.dataSelectorDrawer = value;
        },
        updateVisibleBudg: async function(configuration_id){
            this.cur_visible_budgets_to_save = this.dataManager.getVisibleBudgets();
            if(this.omniChannelEnabled && configuration_id != 'incr') {
                this.buildOmniChannel();
            }
            else{
                this.loading_new_data = true;
                await this.sleep(100);
                this.loading_new_data = false;
            }
        },
        buildTree: async function(){
            this.configTreeLoading = true;
            this.configurationTree = new Tree();
            let configurationMap = null;
            // if(this.$configuration.has_param('config_end_point')){
            //     configurationMap = await apiCaller.get_all_configs(this.apiToken,this.$configuration.get_config_param('config_end_point'));
            // }
            // else{
            configurationMap = await apiCaller.get_all_configs(this.apiToken);
            // }
            this.configurationTree.addConfigurationMap(configurationMap);
            console.log(this.configurationTree)
            await this.sleep(1000);
            this.update_load_status("configurationTree");
            this.configTreeLoading = false;
        },
        sleep: function(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        update_load_status: function(loaded){
            let vm = this;
            switch(loaded){
                case "configurationTree":
                    this.initial_tree_loaded = true;
                    this.initial_load_status = 40;
                    this.initial_load_text_status = "Loading Initial Scenario";
                    break;
                case "configuration":
                    this.initial_config_loaded = true;
                    this.initial_load_status = 70;
                    this.initial_load_text_status = "Loading Data";
                    break;
                case "data":
                    this.initial_data_loaded = true;
                    this.initial_load_status = 99;
                    this.initial_load_text_status = "Loading Program";
                    setTimeout(function(){
                        vm.initial_load_status = 99;
                        vm.inital_load_text_status = "Loaded";
                        vm.update_load_status()
                    },1000);
                    break;
                default:
                    this.initial_system_load = true;
                    this.loading_new_data = true;
                    setTimeout(function(){
                        vm.loading_new_data = false;
                    }, 10);
            }
        },
        download_pptx: function(){
            apiCaller.add_pptx(this.apiToken);
            let data_stats = this.dataManager.getAllDataStatisticsAtVisibleBudget();
            this.pptx_doc.add_element('unique_reach_curve_max', this.dataManager.getPercentageLimit());
            this.pptx_doc.add_element('statistics', data_stats);
            let data_names = [];
            for(let raw_data_row of Object.entries(data_stats)){
                data_names.push(raw_data_row[1]['Name'])
            }
            this.pptx_doc.add_element('names', data_names);
            let v_ids = this.dataManager.get_visible_ids();
            this.pptx_doc.add_element('configurations', this.runConfigurationsManager.getConfigurationListFromIds(v_ids));
            this.pptx_doc.build();
            this.pptx_doc.set_name(this.download_name);
            this.pptx_doc.save();
            this.pptx_doc = null;
            this.$emit('downloaded');
        },
        download_pdf: async function(){
            apiCaller.add_pdf(this.apiToken)
            let data_stats = this.dataManager.getAllDataStatisticsAtVisibleBudget();
            let data_names = [];
            for(let raw_data_row of Object.entries(data_stats)){
                data_names.push(raw_data_row[1]['Name'])
            }
            this.pdf_doc.add_pdf_element('Statistics',data_stats);
            this.pdf_doc.add_pdf_element('names', data_names);
            let v_ids = this.dataManager.get_visible_ids();
            this.pdf_doc.add_pdf_element('configurations', this.runConfigurationsManager.getConfigurationListFromIds(v_ids));
            this.pdf_doc.build();
            this.pdf_doc.set_pdf_name(this.download_name);
            this.pdf_doc.save();
            this.pdf_doc = null;
            this.$emit('downloaded');
        },
        download_excel: async function(){
            let data_stats = this.dataManager.getAllDataStatisticsAtVisibleBudget();
            let data_list = [];
            for(let data_dict of Object.entries(data_stats)){
                data_dict[1]['OnTargetReachPerc'] = ((data_dict[1]['OnTargetReach']/data_dict[1]['TargetPop'])* 100).toLocaleString() + '%';
                data_list.push(data_dict[1]);
            }
            this.$emit('downloaded',data_list);
        },
        pdf_segment(segment){
            let segment_name = segment['name'];
            let segment_data = segment['data'];
            this.pdf_doc.add_pdf_element(segment_name,segment_data);
            if(this.num_pdf_segments_collected + 1 >= this.num_pdf_segments){
                this.get_pdf_docs = false;
                this.num_pdf_segments_collected = 0;
                this.download_pdf();
            }
            else{
                this.num_pdf_segments_collected += 1;
            }
        },
        pptx_segment(segment){
            let segment_name = segment['name'];
            let segment_data = segment['data'];
            this.pptx_doc.add_element(segment_name,segment_data);
            if(this.num_pptx_segments_collected + 1 >= this.num_pptx_segments){
                this.get_pptx_docs = false;
                this.num_pptx_segments_collected = 0;
                this.download_pptx();
            }
            else{
                this.num_pptx_segments_collected += 1;
            }
        },
        optimize_scenario: async function(budget){
            // let budg_grps_list = this.dataManager.get_budget_grps();
            let budg_grps_list = this.dataManager.get_budget_reach();
            let data = {
                'budget': budget,
                'budget_grps': budg_grps_list
            }
            let result = await apiCaller.optimize(this.apiToken,data);
            result = result['result'];
            let visible_ids = this.dataManager.get_visible_ids();
            let incr_inc = false;
            for(let v_idx in visible_ids){
                if(v_idx == 'incr'){
                    incr_inc = true;
                    continue;
                }
                this.dataManager.setVisibleBudget(visible_ids[v_idx],result[v_idx]);
            }
            if(incr_inc){
                this.updateVisibleBudg('incr');
            }
            else{
                this.updateVisibleBudg(0);
            }
        },
        set_omni_channel: async function(){
            this.omniChannelEnabled = !this.omniChannelEnabled;
            if(this.omniChannelEnabled){
                this.buildOmniChannel();
            }
            else{
                this.loading_new_data = true;
                this.dataManager.deleteDataObjectList(['incr']);
                await this.sleep(100);
                this.loading_new_data = false;
            }
        },
        buildOmniChannel: async function(){
            let visible_ids = this.dataManager.get_visible_ids();
            let omni_compatible = this.runConfigurationsManager.are_configurations_omni_channel_compatible(visible_ids);
            if(omni_compatible){
                this.loading_new_data = true;
                this.loading_new_scenarios = true;
                this.current_scenario_loading = 'Incremental Reach Curve'
                let config = this.runConfigurationsManager.build_omni_channel_config(visible_ids);
                let budgets = this.dataManager.getVisibleBudgets();
                config['omni_budg'] = budgets;
                apiCaller.add_run(this.apiToken);
                let data_response = [];
                if(this.pricing_scheme == null){
                    data_response = await apiCaller.process_config(this.apiToken,config).catch((e)=>{
                        console.log(e)
                        return false;
                    });
                }
                else if(this.pricing_scheme == false){
                    data_response = await apiCaller.process_config_national(this.apiToken,config).catch((e)=>{
                        console.log(e)
                        return false;
                    });
                }
                else if(this.pricing_scheme == true){
                    data_response = await apiCaller.process_config_local(this.apiToken,config).catch((e)=>{
                        console.log(e)
                        return false;
                    });
                }
                if(this.dataManager.hasConfigBeenRun('incr')){
                    this.dataManager.updateDataObject('incr',data_response['data']);
                    this.runConfigurationsManager.replaceConfiguration('incr', config);
                }
                else{
                    this.dataManager.addNewDataObject('incr', data_response['data']);
                    this.runConfigurationsManager.addNewConfigurationFromConfigurationAt(config, 'incr');
                }
                let targPop = await apiCaller.target_population(this.apiToken,config);
                this.dataManager.setTargetPop('incr',targPop['targetPop']);
                this.dataManager.addVisibleConfiguration({'Id': 'incr', 'Name': 'Incremental Reach Curve'});
                this.runConfigurationsList = this.runConfigurationsManager.getConfigurationList();
                this.targetPop = this.dataManager.getTargetPopGreatest();
                let budg = this.dataManager.getConfigBudgetPoints('incr');
                this.dataManager.setVisibleBudget('incr',budg[budg.length-1]);
                this.loading_new_data = false;
                this.loading_new_scenarios = false;
                this.loading_percentage = 0;
                this.current_scenario_loading = '';
            }
        }
    }
}
</script>