class SweetSpotConfiguration{
    constructor(market='Calgary', audience = 'None', gender = [], ageRange = [], startDate = null, endDate = null, cpm = 5, budgetRange = [0,100000], product="billboards", timeRange="4 Weeks", operator = "Pattison"){
        this._configuration = {
            'Market': market,
            'Audience': audience,
            'Gender': gender,
            'AgeRange': ageRange,
            'StartDate': startDate,
            'EndDate': endDate,
            'CPM': cpm,
            'BudgetRange': budgetRange,
            'Product': product,
            'NumberOf': 1,
            'TimeRange': timeRange,
            'Operator': operator
        }
        this.configuration_name = 'DEFAULT'
        this.configuration_id = -1;
    }
    get_configuration_name(){
        return this.configuration_name;
    }
    set_configuration_name(configuration_name){
        this.configuration_name = configuration_name;
    }
    get_configuration_id(){
        return this.configuration_id;
    }
    set_configuration_id(configuration_id){
        this.configuration_id = configuration_id;
    }
    get_configuration(){
        return this._configuration;
    }
    updateField(field,value){
        if(field == 'Name'){
            this.configuration_name = value;
        }
        else if(field == 'Id'){
            this.configuration_id = value;
        }
        else if(typeof value == 'object') {
            this._configuration[field] = JSON.parse(JSON.stringify(value));
        }
        else if(typeof value == 'string') {
            this._configuration[field] = value;
        }
    }
    getField(field){
        return this._configuration[field];
    }
    updateStartDate(startDate){
        this._configuration['StartDate'] = startDate;
    }
    updateEndDate(endDate){
        this._configuration['EndDate'] = endDate;
    }
    setRandomConfigurationFromTree(configTree){
        var markets = configTree.getValuesAtLevel('Market',this._configuration);
        this.updateField('Market',markets[0]);
        this.updateField('Audience','None');
        var genders = configTree.getValuesAtLevel('Gender',this._configuration);
        this.updateField('Gender',genders);
        var ageRange = configTree.getValuesAtLevel('AgeRange',this._configuration);
        console.log(ageRange)
        this.updateField('AgeRange',ageRange);
        var dateRange = configTree.getDateRangeFromConfig(this._configuration);
        let startDate = new Date(dateRange[0]);
        let endDate = new Date(dateRange[0]);
        if(this._configuration.Market == 'Calgary'){
            endDate.setDate(endDate.getDate() + 7 * 8);
        }
        else {
            endDate.setDate(endDate.getDate() + 7 * 4);
        }
        this.updateField('StartDate',startDate.toISOString().substr(0,10));
        this.updateField('EndDate',endDate.toISOString().substr(0,10));
        if(this._configuration.Market == 'Calgary'){
            this.updateField('Product','Kong Poster');
        }
        else if(this._configuration.Market == 'Laval'){
            this.updateField('Product','SuperBus')
        }
        else if(this._configuration.Market == 'NYC'){
            this.updateField('Product','All')
        }
        else {
            this.updateField('Product','Flexity');
        }
    }
    isConfigEqual(configuration){
        let fieldsDesired = [];
        if(Object.keys(configuration).includes('Discount') || Object.keys(this._configuration).includes('Discount')){
            fieldsDesired = ['Audience', 'Gender', 'AgeRange', 'StartDate', 'EndDate', 'Product', 'Market', 'Discount'];
        }
        else {
            fieldsDesired = ['Audience', 'Gender', 'AgeRange', 'StartDate', 'EndDate', 'Product', 'Market',];
        }
        for(let field of fieldsDesired){
            if(!Object.keys(configuration).includes(field) || !Object.keys(this._configuration).includes(field)){
                return false;
            }
            else if(Array.isArray(this._configuration[field]) && Array.isArray(configuration[field]) && configuration[field].length == this._configuration[field].length){
                for(let val of this._configuration[field]){
                    if(!configuration[field].includes(val)){
                        return false;
                    }
                }
            }
            else if(typeof this._configuration[field] == "string" && typeof configuration[field] == "string"){
                if(this._configuration[field] != configuration[field]){
                    return false;
                }
            }
            else if(typeof this._configuration[field] == "number" && typeof configuration[field] == "number"){
                if(this._configuration[field] != configuration[field]){
                    return false;
                }
            }
            else if(field == undefined || field == null){
                continue;
            }
            else {
                return false;
            }
        }
        return true;
    }
    omni_channel_compatible(configuration){
        let fieldsDesired = [];
        if(Object.keys(configuration).includes('Discount') || Object.keys(this._configuration).includes('Discount')){
            fieldsDesired = ['Audience', 'Gender', 'AgeRange', 'StartDate', 'EndDate', 'Discount'];
        }
        else {
            fieldsDesired = ['Audience', 'Gender', 'AgeRange', 'StartDate', 'EndDate'];
        }
        for(let field of fieldsDesired){
            if(!Object.keys(configuration).includes(field) || !Object.keys(this._configuration).includes(field)){
                return false;
            }
            else if(Array.isArray(this._configuration[field]) && Array.isArray(configuration[field]) && configuration[field].length == this._configuration[field].length){
                for(let val of this._configuration[field]){
                    if(!configuration[field].includes(val)){
                        return false;
                    }
                }
            }
            else if(typeof this._configuration[field] == "string" && typeof configuration[field] == "string"){
                if(this._configuration[field] != configuration[field]){
                    return false;
                }
            }
            else if(typeof this._configuration[field] == "number" && typeof configuration[field] == "number"){
                if(this._configuration[field] != configuration[field]){
                    return false;
                }
            }
            else if(field == undefined || field == null){
                continue;
            }
            else {
                return false;
            }
        }
        return true;
    }
}
export default SweetSpotConfiguration