<template>
  <v-card style="height: 100%; width: 100%;">
      <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-if="configurations == null" align="center" justify="center">
          <v-col cols="auto">
              <v-progress-circular indeterminate size="100"></v-progress-circular>
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else-if="configurations == -1" align="center" justify="center">
          <v-col cols="auto">
              <span class="no-data-available-title">No Data Available</span>
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" align="center" justify="center" v-else-if="filter_page == 'campaign_filtering'">
          <v-col cols="12" class="pa-0">
            <v-row class="ma-0 pa-0" align="center" justify="center">
              <v-col cols="auto">
                <span class="filter-title">Campaign Filtering</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto" v-if="!filters_changed">
                <v-icon color="black" @click="filter_page = 'campaign_selection'; updated_configuration();">mdi-arrow-right</v-icon>
              </v-col>
              <v-col cols="auto" v-else>
                <v-btn class="filter-control" outlined @click="filter_campaigns">
                  <v-icon>mdi-magnify</v-icon>
                  Search
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pt-0">
              <v-radio-group v-model="date_inclusivity" class="ma-0 pa-0 filter-control" @change="filters_changed = true; updated_configuration();" row>
                  <template v-slot:label>
                      <span>The Campaign</span>
                  </template>
                  <v-radio label="Start Date" value="start"></v-radio>
                  <v-radio label="End Date" value="end"></v-radio>
                  <v-radio label="Both" value="both"></v-radio>
              </v-radio-group>
          </v-col>
          <v-col cols="12" class="pa-0">
              <DateRange :start_date="start_date" :end_date="end_date" :min_start_date="min_start_date" :max_end_date="max_end_date" v-on:date_update="campaign_filtering_date_updated"></DateRange>
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else-if="campaigns == null" align="center" justify="center">
          <v-col cols="auto">
              <v-progress-circular indeterminate size="100"></v-progress-circular>
          </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else-if="campaigns == -1" align="start" justify="center">
        <v-col cols="12" class="pa-0">
          <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col cols="auto" class="pr-0">
              <v-icon color="black" @click="filter_page = 'campaign_filtering'">mdi-arrow-left</v-icon>
            </v-col>
            <v-col cols="auto">
              <span class="filter-title">View Campaign</span>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>  
        <v-col cols="auto">
          <span class="no-data-available-title" >No Data Available</span>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-else-if="filter_page == 'campaign_selection'">
        <v-col cols="12" class="pa-0">
          <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col cols="auto" class="pr-0">
              <v-icon color="black" @click="filter_page = 'campaign_filtering'; updated_configuration();">mdi-arrow-left</v-icon>
            </v-col>
            <v-col cols="auto">
              <span class="filter-title">View Campaign</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon color="black" @click="filter_page = 'frequency_overview';">mdi-rotate-3d-variant</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-row class="ma-0 pa-0" justify="space-around">
            <v-col cols="auto" class="pr-1">
              <v-btn :disabled="Object.keys(campaigns).indexOf(selected_campaign_id) == 0" @click="shift_campaign_id(-1)" icon>
                <v-icon color="black">mdi-arrow-left-circle</v-icon>
              </v-btn>
            </v-col>
            <v-col sm="5" md="7" lg="8" class="px-1">
              <v-select v-model="selected_campaign_id" class="ma-0 pa-0" :items="Object.keys(campaigns)" label="Campaign ID" @change="select_campaign($event)"></v-select>
            </v-col>
            <v-col cols="auto" class="pl-1">
              <v-btn :disabled="Object.keys(campaigns).indexOf(selected_campaign_id) == (Object.keys(campaigns).length - 1)" @click="shift_campaign_id(1)" icon>
                <v-icon color="black">mdi-arrow-right-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
            <DateRange :start_date="selected_campaign_start_date" :end_date="selected_campaign_end_date" :min_start_date="selected_campaign_min_start_date" :max_end_date="selected_campaign_max_end_date"></DateRange>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" v-else-if="filter_page == 'frequency_overview'" style="height: 100%">
        <v-col cols="12" class="pa-0">
          <v-row class="ma-0 pa-0" align="center" justify="center">
            <v-col cols="auto">
              <span class="filter-title">Frequency</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-icon color="black" @click="filter_page = 'campaign_selection';">mdi-rotate-3d-variant</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pt-0" style="height: calc(100% - 60px)">
          <ReportingFrequencyDistribution :data="data" :stat="stat" :type="type"></ReportingFrequencyDistribution>
        </v-col>
      </v-row>
  </v-card>
</template>

<script>
  import DateRange from './DateRange.vue';
  import ReportingFrequencyDistribution from './ReportingFrequencyDistribution.vue';
  export default {
    name: "ReportingReachCurve",
    components: {
      DateRange,
      ReportingFrequencyDistribution
    },
    props: [
      'configurations',
      'campaigns',
      'selected_campaign',
      'data',
      'stat',
      'type'
    ],
    data () {
      return {
        filter_page: 'campaign_filtering',
        //Campaign Filters
        min_start_date: '2023-06-20',
        max_end_date: '2023-06-20',
        start_date: '2023-06-20',
        end_date: '2023-06-20',
        date_inclusivity: 'start',
        filters_changed: true,
        //Selected Campaign
        selected_campaign_id: null,
        campaign_selectors_available: false,
        selected_campaign_start_date: '',
        selected_campaign_end_date: '',
        selected_campaign_min_start_date: '',
        selected_campaign_max_end_date: '',
      }
    },
    mounted(){
    },
    watch: {
      configurations: {
          handler: function(){
            if (this.configurations != null){
              this.min_start_date = this.configurations['min_start_date'];
              this.max_end_date = this.configurations['max_end_date'];
              this.start_date = this.configurations['start_date'];
              this.end_date = this.configurations['end_date'];

              this.date_inclusivity = this.configurations['date_inclusivity'];

              this.filters_changed = this.configurations['filters_changed'];
              this.filter_page = this.configurations['filter_page'];
            }
          },
          immediate: true,
          deep: true
      },
      selected_campaign: {
          handler: function(){
            console.log('campaign', this.selected_campaign)
            if(this.selected_campaign != null){
              this.selected_campaign_start_date = this.selected_campaign['start_date'];
              this.selected_campaign_end_date = this.selected_campaign['end_date'];
              this.selected_campaign_id = this.selected_campaign['campaign_id']
            }
          },
          immediate: true,
          deep: true
      }
    },
    methods: {
      //Campaign Filtering
      updated_configuration: function(){
        this.$emit('updated_config', {
          'start_date': this.start_date,
          'end_date': this.end_date,
          'inclusivity': this.date_inclusivity,
          'filters_changed': this.filters_changed,
          'filter_page': this.filter_page
        })
      },
      campaign_filtering_date_updated: function(date){
        this.start_date = date[0];
        this.end_date = date[1];
        this.filters_changed = true;
        this.updated_configuration();
      },
      filter_campaigns: function(){
        let filter = {'start_date': new Date(this.start_date).toISOString().slice(0, 10).split('-').reverse().join('-'),
                    'end_date': new Date(this.end_date).toISOString().slice(0, 10).split('-').reverse().join('-'),
                    'inclusivity': this.date_inclusivity}
        if (this.filters_changed){
          this.$emit('filter_campaigns', filter);
          this.filters_changed = false;
        }
        this.filter_page = 'campaign_selection'
      },
      //Campaign Selection 
      selected_campaign_date_updated: function(date){
        this.selected_campaign_start_date = date[0];
        this.selected_campaign_end_date = date[1];
      },
      select_campaign: function(){
        let campaign_info = this.campaigns[this.selected_campaign_id];
        this.selected_campaign_min_start_date = campaign_info['campaign_start_date'];
        this.selected_campaign_max_end_date = campaign_info['campaign_end_date'];
        this.selected_campaign_start_date = campaign_info['campaign_start_date'];
        this.selected_campaign_end_date = campaign_info['campaign_end_date'];

        let body = {
            'campaign_id': this.selected_campaign_id,
            'time_grouping': 'daily'
        };
        let campaign = {
          'campaign_id': this.selected_campaign_id,
          'start_date': this.selected_campaign_start_date,
          'end_date': this.selected_campaign_end_date
        }
        this.$emit('switch_campaign',body,campaign);
      },
      shift_campaign_id: function(shift_by){
        let campaign_lst = Object.keys(this.campaigns);
        let cur_idx = campaign_lst.indexOf(this.selected_campaign_id);
        cur_idx += shift_by;
        if(cur_idx < 0 || cur_idx == campaign_lst.length){
          return;
        }
        else{
          this.selected_campaign_id = campaign_lst[cur_idx];
          this.select_campaign();
        }
      }
    }
  }
</script>

<style scoped>
  .no-data-available-title{
    font-family: 'Poppins';
    font-weight: 200;
    font-size: 1.2em;
  }

  .filter-title{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 1.2em;
    line-height: 36px;
  }
  .filter-control{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 0.9em;
  }
</style>
