<template>
    <v-card style="height: 100%; width: 100%;">
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-if="data == null" align="center" justify="center">
            <v-col cols="12">
                <v-progress-linear indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" v-else>
            <v-col cols="4">
                <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                    <v-col class="ma-0 pa-0" cols="12" align="center">
                        <span class="highlight_stat">{{ data['reach'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                    </v-col>
                    <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                    <v-col class="ma-0 pa-0" cols="12" align="center">
                        <span class="highlight_title">Reach</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                    <v-col class="ma-0 pa-0" cols="12" align="center">
                        <span class="highlight_stat">{{ data['impressions'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                    </v-col>
                    <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                    <v-col class="ma-0 pa-0" cols="12" align="center">
                        <span class="highlight_title">Impr.</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <v-row class="ma-0 pa-0" style="height: 100%; width: 100%;" align-content="center" justify="center">
                    <v-col class="ma-0 pa-0" cols="12" align="center">
                        <span class="highlight_stat">{{ data['frequency'].toLocaleString("en-CA",{ minimumFractionDigits: 1, maximumFractionDigits: 1, notation: 'compact'}) }}</span>
                    </v-col>
                    <v-divider color="#FD6262" class="my-0 mx-1 pa-0" inset></v-divider>
                    <v-col class="ma-0 pa-0" cols="12" align="center">
                        <span class="highlight_title">Frq.</span>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'ReportingHighlightsShort',
    props: [
        'data'
    ],
    mounted(){
    },
    computed: {
    },
    data: () => ({
        selected_campaign_highlights: {
                'impressions': 0, 'reach': 0, 'frequency': 0
        }
    }),
    methods: {
    },
    watch: {
    }
}
</script>

<style scoped>
    .highlight_stat{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.4em;
    }
    .highlight_title{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.8em;
    }
</style>