import Vue from 'vue';
import App from './App.vue';
import router from './router';
import firebase from 'firebase';
import 'firebase/firestore';
import vuetify from './plugins/vuetify';
import gcharts from './plugins/googleCharts';
import excel from './plugins/excel-export';
import config from './configs/docma_config';

// Import the Auth0 configuration
import { domain, clientId, authorizationParams } from '../auth_config.json';

// Import the plugin
import { Auth0Plugin } from './auth';

Vue.config.productionTip = false;

let app = '';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDLKsiU1El1AOP36xrhT7x9N3QvGclxnmQ",
    authDomain: "docmasweetspot.firebaseapp.com",
    projectId: "docmasweetspot",
    storageBucket: "docmasweetspot.appspot.com",
    messagingSenderId: "726449683180",
    appId: "1:726449683180:web:3890007eeb898c021243c9",
    measurementId: "G-RQGBCTEEY7"
}
// const firebaseConfig = {
//     apiKey: "AIzaSyBR8CZSAdqbR4nNnj4cejK0Uk97es1kI7g",
//     authDomain: "docma-8305e.firebaseapp.com",
//     databaseURL: "https://docma-8305e.firebaseio.com",
//     projectId: "docma-8305e",
//     storageBucket: "docma-8305e.appspot.com",
//     messagingSenderId: "855712345437",
//     appId: "1:855712345437:web:69eb0159f7c553b545ccb4",
//     measurementId: "G-6MLXMRT8XW"
// };
// Broadsign config
// const firebaseConfig = {
//     apiKey: "AIzaSyDSJHEL27XF3BMiO-CHm7OfENI1lnIYs78",
//     authDomain: "broadsign-sweet-spot.firebaseapp.com",
//     projectId: "broadsign-sweet-spot",
//     storageBucket: "broadsign-sweet-spot.appspot.com",
//     messagingSenderId: "427410639694",
//     appId: "1:427410639694:web:262a5525cb00b4bf98ac1f"
// };
Vue.prototype.$token = {
    val: "",
    ova: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1c2VyIjoidGdyYW50QGRvY21hLmNhIiwiZXhwIjoxNzI1NzgzMzU3fQ.0eRFAylZbIZ0Z02DIUGJpzGa7xBBj0uanMssq0RrgJWrBlGREXxxqequo5snTT7gimrt4C8CNAw4Pxd-UoBf5w",
    set_val: function(token){
        this.val = token;
    },
    get_val: function(){
        return this.val;
    }};
Vue.prototype.$can_save = {
    val: false,
    set_val: function(can_save){
        this.val = can_save;
    },
    get_val: function(){
        return this.val;
    }
};
Vue.prototype.$can_export = {
    val: false,
    set_val: function(exp){
        this.val = exp;
    },
    get_val: function(){
        return this.val;
    }
}; 
Vue.prototype.$can_optimize = {
    val: false,
    set_val: function(optimize){
        this.val = optimize;
    },
    get_val: function(){
        return this.val;
    }
}; 
Vue.prototype.$can_find_incremental_reach = {
    val: false,
    set_val: function(incr_reach){
        this.val = incr_reach;
    },
    get_val: function(){
        return this.val;
    }
};
Vue.prototype.$configuration = {
    val: config['config'],
    get_config: function(){
        return this.val;
    },
    get_config_param: function(param){
        return this.val[param];
    },
    has_param: function(param){
        return this.val.hasOwnProperty(param);
    }
}
//Dev envionment
// var firebaseConfig = {
//     apiKey: "AIzaSyDRVAJ8WQF4ltWy563EbT1f8VhUl6ZMfyg",
//     authDomain: "docmadevenvironment.firebaseapp.com",
//     databaseURL: "https://docmadevenvironment.firebaseio.com",
//     projectId: "docmadevenvironment",
//     storageBucket: "docmadevenvironment.appspot.com",
//     messagingSenderId: "1028883966375",
//     appId: "1:1028883966375:web:a04ef6f95ffc307baf6e82",
//     measurementId: "G-S9WBKGZLPF"
// };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    authorizationParams,
    onRedirectCallback: appState => {
      history.replaceState({}, document.title, window.location.pathname);
      console.log("on redirect callback")
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : "/planning"
      );
    }
  });
  
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app');

// firebase.auth().onAuthStateChanged(() => {
//     if (!app) {
//         /* eslint-disable no-new */
//         app = new Vue({
//             router,
//             vuetify,
//             gcharts,
//             excel,
//             render: h => h(App)
//         }).$mount('#app');
//     }
// });