<template>
    <v-card style="height: 100%; width: 100%;">
        <v-row class="ma-0 pa-0" style="height: 60%; width: 100%;" align="center" justify="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <span class="highlight_stat" v-if="data == null || data == -1"> - </span>
                <span class="highlight_stat" v-else>{{data['highlights'][data_key].toLocaleString("en-CA",{minimumFractionDigits: 0, maximumFractionDigits: 1, notation: 'compact'})}}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
        <v-row class="ma-0 pa-0" style="height: 40%; width: 100%;" align="center" justify="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <span class="highlight_title" v-if="title == null || data == -1"> Data Point </span>
                <span class="highlight_title" v-else>{{title}}</span>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'ReportingMajorHighlight',
    props: [
        'data',
        'title',
        'data_key'
    ],
    mounted(){
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
    },
    watch: {
    }
}
</script>

<style scoped>
    .highlight_card_title{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.1em;
    }
    .highlight_stat{
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 1.4em;
    }
    .highlight_title{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.8em;
    }
    .no-data-available-title{
        font-family: 'Poppins';
        font-weight: 200;
        font-size: 1.2em;
    }
</style>