<template>
  <v-app>
    <!-- 
      User Nav Drawer 
    -->
    <v-navigation-drawer
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      v-model="user_drawer"
      temporary
      absolute
      app
      color="primary"
      dark
      right
    >
      <template v-slot:prepend>
        <v-list-item one-line>
          <v-list-item-avatar style="background-color: #ffffff50">
            <img
              :src="$auth.user.picture"
              :alt="$auth.user.name"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">{{
              $auth.user.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar><v-icon>mdi-account-group-outline</v-icon></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-user">
              <div class="organization-title">
                <div class="organization-title-content">
                  {{user_orgs && user_orgs[$auth.user.org_id] ? user_orgs[$auth.user.org_id]['display_name'] : $auth.user.org_id}}
                </div>
                <v-tooltip left v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                      <v-icon dark color="#FFFFFF">mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Switch Organization
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider class="ma-0 pa-0 mx-6"></v-divider>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="docma-text-navigation-logout" block @click="logout">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- 
      APP Bar
     -->
    <v-app-bar
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      app
      color="primary"
      dark
      height="45px"
      extension-height="48px"
      class="ma-0 pa-0"
    >
      <v-row class="ma-0 pa-0 px-3" max-height="100%">
        <v-col cols="4" class="ma-0 pa-0" style="max-height: 45px">
          <v-img
            :src="require(`${main_logo_inverterd}`)"
            contain
            max-width="150px"
            max-height="30px"
          ></v-img>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-0 pa-0 docma-text-navigation-bar-btn"
          text
          plain
          dark
          @click="user_drawer = true"
        >
          <span class="docma-text-navigation-bar-text"
            >Welcome {{ $auth.user.given_name }}</span
          >
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
        <v-divider vertical color="white" class="ma-0 pa-0 mx-3"></v-divider>
        <v-btn class="ma-0 pa-0 docma-text-navigation-bar-btn" text plain dark disabled>
          <span class="docma-text-navigation-bar-text">Help</span>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-divider vertical color="white" class="ma-0 pa-0 mx-3"></v-divider>
        <v-btn
          class="ma-0 pa-0 docma-text-navigation-bar-btn"
          text
          plain
          dark
          @click="feedback = true"
        >
          <span class="docma-text-navigation-bar-text">Feedback</span>
          <v-icon>mdi-comment-quote-outline</v-icon>
        </v-btn>
      </v-row>

      <template v-slot:extension>
        <v-row class="ma-0 pa-0" style="height: 100%">
          <v-col cols="10" class="ma-0 pa-0 px-3" style="max-height: 48px">
            <v-tabs v-model="mainTab" align-tabs="start" dark>
              <v-tab class="docma-tool-navigation-bar" to="/planning">Planning</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/reporting">Reporting</v-tab>
            </v-tabs>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-icon>mdi-bell</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <!-- 
      Feedback
    -->
    <v-overlay v-model="feedback">
      <FeedbackCard v-on:close="feedback = false"></FeedbackCard>
    </v-overlay>
    <!-- 
      APP Content
     -->
    <v-main
      style="
        height: calc(100vh-98px);
        max-height: calc(100vh-98px);
        overflow-y: hidden;
        overflow-x: hidden;
      "
      v-if="environment_enabled"
    >
      <router-view />
    </v-main>
  </v-app>
  <!-- <v-app syle="max-height: 100vh">
    <v-app-bar app color="third">
        <v-container fluid style="max-height:56px; min-height: 56px; padding: 0px;">
            <v-row class="ma-0 pa-0" style="max-height:inherit; min-height: inherit;" align-content="center">
                <v-col sm="3" style="max-height:inherit;">
                </v-col>
                <v-col sm="6" style="max-height:inherit; min-height: inherit;">
                    <v-img :src="require(`${main_logo}`)" contain max-height="100%"></v-img>
                </v-col>
                <v-col sm="3" style="max-height:inherit;">
                    <div class="mx-1" style="float:right; height:100%">
                        <v-icon color="black" v-if="loggedIn" @click="logout" style="height: 100%">mdi-exit-run</v-icon>
                    </div>
                    <v-divider v-if="loggedIn && $can_export.get_val()" class="mx-1" vertical style="float:right; border-color:#616161;" dark></v-divider>
                    <div class="mx-1" style="float:right; height:100%">
                        <v-icon color="black" v-if="loggedIn && $can_export.get_val()" style="height: 100%" @click="download_type_selector=true">mdi-download</v-icon>
                    </div>
                    <v-divider v-if="this.loggedIn && user_can_switch_pricing" class="mx-1" vertical style="float:right; border-color:#616161;" dark></v-divider>
                    <div class="mx-1" style="float:right; height:100%">
                        <v-switch dark hide-details v-if="loggedIn && user_can_switch_pricing" class="ma-0 pa-0" style="height: 100%;" color="white" v-model="pricing_scheme" label="Local Pricing"></v-switch>
                    </div>                    
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
    <v-content color="black" style="height: 100vh; width: 100vw; position: fixed">
        <router-view v-on:newApiToken="setApiToken" :apiToken="apiToken" :download="downloading" :download_type="download_type" :download_name="download_name" :pricing_scheme="pricing_scheme" v-on:downloaded="setDownloadPdf"/>
        <v-navigation-drawer
                app
                v-model="navDrawer"
                absolute
                temporary
                right
                :width="userDrawerWidth">
            <user-profile-drawer :open="navDrawer" v-on:updateDrawerWidth="updateDrawerWidth" :apiToken="apiToken"></user-profile-drawer>
        </v-navigation-drawer>
        <v-overlay :value="help">
            <help-main v-on:closeHelpMenu="help = false;"></help-main>
        </v-overlay>
        <v-overlay :value="download_type_selector">
            <v-card style="padding: 15px;">
                <v-container v-if="downloading" style="height: 100%; width: 100%;">
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-container>
                <v-container v-else style="height: 100%; width: 100%;">
                    <v-row class="ma-0 pa-0" style="width: 100%;">
                        <v-col class="my-0 py-0" sm="12">
                            <v-text-field v-model="download_name" outlined label="Export Name: "></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" style="width: 100%;">
                        <v-col class="mb-0 pb-0" sm="12">
                            <span>Select a Format:</span>
                        </v-col>
                        <v-col class="mt-0 pt-0" sm="12">
                            <v-radio-group v-model="download_type">
                                <v-radio label=".pptx Format" value="pptx"></v-radio>
                                <v-radio label=".pdf Format" value="pdf"></v-radio>
                                <v-radio label=".xls Format" value="xls"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" style="width: 100%;">
                        <v-col sm="6">
                            <v-btn v-if="download_type != 'xls'" style="width: 100%; height: 100%;" @click="download()"><v-icon>mdi-download-outline</v-icon></v-btn>
                            <download-excel v-else class="btn btn-default" :fetch="downloadExcel" :fields="excel_export_fields" type="csv" name="SweetSpot.xls">
                                <v-btn style="width: 100%; height: 100%;"><v-icon>mdi-download-outline</v-icon></v-btn>
                            </download-excel>
                        </v-col>
                        <v-col sm="6">
                            <v-btn style="width: 100%; height: 100%;" @click="download_type_selector=false"><v-icon>mdi-close</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-overlay>
    </v-content>
  </v-app> -->
</template>

<script>
    import firebase from 'firebase';
    import userProfileDrawer from './components/userProfileDrawer'
    import api_caller from './javascript/api_caller'

    import org_api_caller from "./javascript/data_info.js";
    export default {
        name: 'App',
        components: {
            userProfileDrawer,
        },
        data () {
            return {
                main_logo_inverterd: "./assets/docma-logo-white-red.svg",

                logged_in: false,
                user_drawer: false,
                feedback: false,
                user_orgs: {},

                user_first_name: "",
                user_full_name: "",

                tool_state: null,

                my_brands: {},
                environments: [],

                rating: 5,
                rating_follow_up: true,

                rating_success: false,
                rating_error: false,

                mainTab: 1,

                environment_enabled: true,

                brands_pulled: false,



                // loggedIn: false,
                // navDrawer: false,
                // apiToken: "",
                // profileBoxHeight: 0,
                // nameOfUser: '',
                // color: '',
                // updatedNameOfUser: '',
                // updatedColor: '',
                // download_type_selector: false,
                // downloading: false,
                // download_type: 'pptx',
                // download_name: '',
                // help: false,
                // pdf_help_guide: require('@/assets/Getting_Started_Guide.pdf'),
                // userDrawerWidth: 312,
                // user_can_switch_pricing: null,
                // user_can_export: false,
                // pricing_scheme: null,
                // excel_export_fields: {
                //     "Curve Name": "Name",
                //     "Target Population": "TargetPop",
                //     "Product Quantity": "NumberOf",
                //     "Unique Reach": "OnTargetReach",
                //     "Unique Reach %": "OnTargetReachPerc",
                //     "Average Frequency": "AvgFrequency",
                //     "Total Impressions": "Impressions",
                //     "Total Budget": "Budget",
                //     "GRPs": "GRPS",
                //     "CPM": "CPM"
                // },
                // excel_export_data: [],
                // font_path: 'docma',
                // main_logo: './assets/docma-logo-reversed.svg'
            }
        },
        mounted () {
            let font_path = this.$configuration.get_config_param('font_path');
            import(`./css/${font_path}/highlights.css`)
            import(`./css/${font_path}/dataSelector.css`);
            import(`./css/${font_path}/login.css`);
            import(`./css/${font_path}/profileDrawer.css`);
            import(`./css/${font_path}/help.css`);
            import(`./css/${font_path}/uniqueReachCurveToolTip.css`);
            import(`./css/${font_path}/configDetails.css`);
            import(`./css/${font_path}/systemFeedback.css`);
            // this.$vuetify.theme.themes.light.primary = this.$configuration.get_config_param('primary');
            // this.$vuetify.theme.themes.light.secondary = this.$configuration.get_config_param('secondary');
            // this.$vuetify.theme.themes.light.third = this.$configuration.get_config_param('third');
            this.main_logo = this.$configuration.get_config_param('main_logo_path');
            // this.isUserLoggedIn()
            if (this.$auth.isAuthenticated) {
              this.user_first_name = this.$auth.user.given_name;
              this.user_full_name = this.$auth.user.name;
            }
        },
        updated (){
            // this.isUserLoggedIn()
          if (this.$auth.isAuthenticated) {
            // this.get_my_projects();
            this.get_my_orgs();
          }
        },
        methods: {
            isUserLoggedIn: async function(){
                const currentUser = this.$token.get_val();
                if (currentUser){
                    if(this.pricing_scheme == null){
                        await this.canSwitchPricing(currentUser);
                    }
                    await this.canUseSaveFunctionality(currentUser);
                    await this.canUseExportFunctionality(currentUser);
                    await this.canUseOptimizerFunctionality(currentUser);
                    await this.canUseIncrementalReachFunctionality(currentUser);
                    this.loggedIn = true;
                }
                else {
                    this.$can_export.set_val(false);
                    this.$can_save.set_val(false);
                    this.$can_optimize.set_val(false);
                    this.$can_find_incremental_reach.set_val(false);
                    this.loggedIn = false
                }
            },
            logout: function(){
                firebase.auth().signOut().then( () => {
                    this.$router.replace('Login')
                })
            },
            download: function(){
                this.downloading = true;
            },
            downloadExcel: async function(){
                this.excel_export_data = [];
                this.downloading = true;
                while(this.downloading){
                    await this.sleep(500);
                }
                return this.excel_export_data;
            },
            setDownloadPdf: function(data){
                if(this.download_type == 'xls'){
                    this.excel_export_data = data;
                }
                this.download_type_selector = false;
                this.downloading = false;
                this.download_name = '';
            },
            openCloseNavDrawer(){
                this.navDrawer = !this.navDrawer;
            },
            setApiToken(token){
                this.apiToken = token;
            },
            updateDrawerWidth(width){
                this.userDrawerWidth = width;
            },
            canSwitchPricing: async function (token){
                let can_switch = false;
                if(this.$configuration.has_param('can_switch')){
                    can_switch = this.$configuration.get_config_param('can_switch')
                }
                else{
                    can_switch = await api_caller.can_switch_pricing(token).then((response)=>{
                        return response['authenticated'];
                    }).catch((err)=>{
                        console.error(err);
                        return false;
                    });
                }
                this.user_can_switch_pricing = can_switch;
                if(this.user_can_switch_pricing){
                    this.pricing_scheme = false;
                }
            },
            canUseSaveFunctionality: async function(token){
                if(this.$configuration.has_param('save')){
                    this.$can_save.set_val(false);
                }
                else{
                    let can_save = await api_caller.can_use_save_functionality(token).then((response)=>{
                        return response['authenticated'];
                    }).catch((err)=>{
                        console.error(err);
                        return false;
                    });
                    this.$can_save.set_val(can_save);
                }
            },
            canUseExportFunctionality: async function(token){
                if(this.$configuration.has_param('export')){
                    this.$can_export.set_val(false);
                }
                else{
                    let can_export = await api_caller.can_use_export_functionality(token).then((response)=>{
                        return response['authenticated'];
                    }).catch((err)=>{
                        console.error(err);
                        return false;
                    })
                    this.$can_export.set_val(can_export)
                }
            },
            canUseOptimizerFunctionality: async function(token){
                if(this.$configuration.has_param('optimizer')){
                    this.$can_optimize.set_val(false);
                }
                else{
                    let can_export = await api_caller.can_use_optimizer_functionality(token).then((response)=>{
                        return response['authenticated'];
                    }).catch((err)=>{
                        console.error(err);
                        return false;
                    })
                    this.$can_optimize.set_val(can_export)
                }
            },
            canUseIncrementalReachFunctionality: async function(token){
                let can_export = await api_caller.can_use_incremental_reach_functionality(token).then((response)=>{
                    return response['authenticated'];
                }).catch((err)=>{
                    console.error(err);
                    return false;
                })
                this.$can_find_incremental_reach.set_val(can_export)
            },            
            sleep: function(ms){
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            logout: function () {
              this.$auth.logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            },
            get_my_orgs: async function () {
              let token = await this.$auth.getTokenSilently();
              let userOrgs = await org_api_caller
                .get_user_orgs(token, this.$auth.user.sub)
                .then((resp) => {
                  return resp["data"];
                })
                .catch((e) => {
                  console.log(e);
                  return false;
                });
              if(userOrgs) {
                this.user_orgs = userOrgs
              }
            },
            switchOrg: function () {
              this.$auth.loginWithRedirect()
            },
        }
    };
</script>

<style scoped>
    @font-face {
        font-family: 'RobotoLight';
        src: local('RobotoLight'),
            url('./fonts/Roboto-Light.ttf') format('truetype');
    }
    @font-face {
        font-family: 'RobotoMedium';
        src: local('RobotoMedium'),
            url('./fonts/Roboto-Medium.ttf') format('truetype');
    }
    @font-face {
        font-family: 'RobotoBold';
        src: local('RobotoBold'),
            url('./fonts/Roboto-Bold.ttf') format('truetype');
    }
    @font-face {
        font-family: 'FuturaTLight';
        src: local('FuturaTLight'),
            url('./fonts/futura-t-light.ttf') format('truetype');
    }
    @font-face {
        font-family: 'FuturaTBold';
        src: local('FuturaTBold'),
        url('./fonts/futura-t-bold.ttf') format('truetype');
    }
    @font-face {
        font-family: 'FuturaTMedium';
        src: local('FuturaTMedium'),
        url('./fonts/futura-t-medium.ttf') format('truetype');
    }
    @font-face {
        font-family: 'Calibri';
        src: local('Calibri'), url('./fonts/calibri-regular.ttf')
    }
    @font-face {
        font-family: 'GothamBold';
        src: local('GothamBold'), url('./fonts/GothamBold.otf')
    }
    @font-face {
        font-family: 'GothamMedium';
        src: local('GothamMedium'), url('./fonts/GothamMedium.otf')
    }
    @font-face {
        font-family: 'GothamBook';
        src: local('GothamBook'), url('./fonts/GothamBook.otf')
    }


    @import url("https://fonts.googleapis.com/css?family=Poppins:200,400,600,800");
    @import url("https://fonts.googleapis.com/css?family=Amiko:400,600,700");
    .docma-tool-navigation-bar {
      font-family: "Poppins" !important;
      font-weight: 600 !important;
      font-size: 0.9em !important;
    }
    .docma-text-navigation-bar-text {
      font-family: "Amiko" !important;
      font-size: 0.7em;
    }
    .docma-text-navigation-bar-btn {
      height: 30px !important;
    }
    .docma-text-navigation-logout {
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 1em !important;
    }
    .docma-text-navigation-user {
      font-family: "Poppins" !important;
      font-weight: 200 !important;
      font-size: 1.1em !important;
    }
    .Environment-name-title {
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 1.2em !important;
      color: white;
    }
    .Brand-name-title {
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 1em !important;
      color: white;
    }
    .docma-text-rating-main-title {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 1.2em;
    }
    .docma-text-rating-title {
      font-family: "Poppins" !important;
      font-weight: 600;
      font-size: 0.9em;
    }
    .docma-text-rating-text {
      font-family: "Poppins" !important;
      font-weight: 200;
      font-size: 1.1em;
    }
    .loginFormAlerts {
      font-family: "Poppins" !important;
      position: absolute;
      width: 100%;
      z-index: 4;
    }
    .organization-title {
      font-family: "Poppins" !important;
      font-weight: 400 !important;
      font-size: 0.7em !important;
      color: white;
      display: flex;
      max-width: 200px;
      align: left;

      .organization-title-content {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: auto;
        margin: auto;
      }
    }
    .organization-content {
      font-size: 1em !important;
      padding-bottom: 12px;
    }

    /deep/ .v-toolbar__content {
      padding: 0px !important;
    }
    /deep/ .v-toolbar__extension {
      padding: 0px !important;
    }
</style>
