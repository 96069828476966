<template>
    <v-row class="ma-0 pa-0" style="height: 100%;">
        <v-col class="py-0 px-0 ml-0" cols="auto" style="width:56px">
            <v-list class="py-0">
                <v-list-item class="pa-0" :style="[dynamicComponent == CONFIG_INFO ? {background: $vuetify.theme.themes.light.primary} : {background: '#FFFFFF'}]">
                    <v-icon v-if="dynamicComponent == CONFIG_INFO" large @click="updateDrawerView('CONFIG_INFO')" style="width: 100%;" :style="[dynamicComponent == CONFIG_INFO ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-clipboard-text-play-outline</v-icon>
                    <v-icon v-if="dynamicComponent != CONFIG_INFO && dynamicComponent != CONFIG_EDIT" large @click="updateDrawerView('CONFIG_INFO')" style="width: 100%;" :style="[dynamicComponent == CONFIG_INFO ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-clipboard-text-play-outline</v-icon>
                    <v-icon v-if="dynamicComponent == CONFIG_EDIT" large @click="updateDrawerView('CONFIG_INFO')" style="width: 100%;" :style="[dynamicComponent == CONFIG_INFO ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-arrow-left</v-icon>
                </v-list-item>
                <v-divider class="mx-1"></v-divider>
                <v-list-item class="pa-0" :style="[dynamicComponent == CONFIG_SAVE ? {background: $vuetify.theme.themes.light.primary} : {background: '#FFFFFF'}]" v-if="save_access">
                    <v-icon large @click="updateDrawerView('CONFIG_SAVE')" style="width: 100%" :style="[dynamicComponent == CONFIG_SAVE ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-content-save-outline</v-icon>
                </v-list-item>
                <v-divider class="mx-1" v-if="save_access"></v-divider>
                <!--<v-list-item class="pa-0" :style="[dynamicComponent == CONFIG_EDIT ? {background: '#00295e'} : {background: '#FFFFFF'}]">-->
                    <!--<v-icon large @click="updateDrawerView('CONFIG_EDIT')" style="width: 100%;" :style="[dynamicComponent == CONFIG_EDIT ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-pencil-outline</v-icon>-->
                <!--</v-list-item>-->
                <!--<v-divider class="mx-1"></v-divider>-->
                <!--<v-list-item class="pa-0" :style="[dynamicComponent == CONFIG_HELP ? {background: '#00295e'} : {background: '#FFFFFF'}]">-->
                    <!--<v-icon large @click="updateDrawerView('CONFIG_HELP')" style="width: 100%;" :style="[dynamicComponent == CONFIG_HELP ? {color: '#FFFFFF'} : {color: '#00295e'}]">mdi-help-circle-outline</v-icon>-->
                <!--</v-list-item>-->
            </v-list>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="py-3 px-0" cols="auto" style="max-height: 100%; max-width=calc(100% - 56px)">
            <keep-alive>
                <component style="max-height: 100%;" :is="dynamicComponent" :configurationManager="configManager" :configList="configList" :configurationEditedId="editingConfigurationId" :undoAvailable="undoAvailable" :projectName="current_open_project" :unsavedChanges="changes_to_be_saved" :resetSelectedConfigs="reset_selected_configs" v-on:selectedReset="reset_selected_configs = false;" v-on:addConfig="addConfig" v-on:duplicateConfig="duplicateConfig" v-on:deleteConfigs="deleteConfigs" :configurationTree="configurationTree" :data="data" :loading="loading" v-on:runConfigurations="runConfigurations" v-on:updateConfiguration="updateConfiguration" v-on:undoDelete="undoDelete()" v-on:EditConfiguration="editConfiguration" v-on:ViewInfoConfiguration="updateDrawerView('CONFIG_INFO_DISP')" v-on:ViewConfiguration="updateDrawerView('CONFIG_INFO')" v-on:openProject="projectOpened" v-on:closeProject="projectClosed" v-on:saveProject="saveProject" v-on:saveNewProject="saveNewProject"></component>
            </keep-alive>
        </v-col>
        <v-col class="pa-0" style="max-width: 30px; width: 30px">
            <v-row class="pa-0 ma-0 grey lighten-2" style="width: 100%; height: 100%; border-top-left-radius: 20px; border-bottom-left-radius: 20px;background-color: #A0ACAD60 !important;" wrap align="center" @click="closeDataSelectorrDrawer()">
                <v-col sm="12" class="pa-0 ma-0">
                    <v-icon @click="closeDataSelectorrDrawer()">mdi-chevron-double-left</v-icon>
                </v-col>
            </v-row>
        </v-col>
        <!--This is the overlay that allows users to select the configurations they want to undo delete-->
        <v-overlay :value="viewUndo">
            <v-card style="padding: 15px; width: 80%; margin-left: 10%; margin-right: 10%;">
                <v-row>
                    <v-col cols="9">
                        <span v-if="this.numToUndo > 1">Select Up To {{this.numToUndo}} Configurations:</span>
                        <span v-else>Select A Configuration:</span>
                    </v-col>
                    <v-col cols="3">
                        <v-icon @click="viewUndo=false">mdi-close</v-icon>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox v-for="config in configurationsAvailableForUndo" :label="config.name" :value="config.id" :key="config.id" @change="verifyUndoSelection(config.id)" v-model="configurationToBeUndone"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                        <v-btn @click="confirmUndo()"><v-icon>mdi-undo</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-overlay>
    </v-row>
</template>

<script>
    import SweetSpotConfigurationManager from "../javascript/sweetSpotConfigurationManager";
    import DataSelectorDrawer from "./dataSelectorDrawerv3"
    import ConfigurationView from "./configurationViewv2"
    import ConfigurationSave from "./configurationSave"
    import filterview from "./filterview"
    import api_caller from "../javascript/save_api_caller";
    export default {
        name: "configurationDrawer",
        props: [
            'inputBudget',
            'configurationTree',
            'configuration',
            'loading',
            'data',
            'configTreeLoading',
            'globalUpdate',
            'visibleBudgToSave'
        ],
        data() {
            return{
                CONFIG_EDIT: DataSelectorDrawer,
                CONFIG_INFO: ConfigurationView,
                CONFIG_HELP: filterview,
                CONFIG_SAVE: ConfigurationSave,
                dynamicComponent: null,
                configManager: null,
                configList: null,
                curConfigId: null,
                curConfig: null,
                configInfoDisplayed: false,
                budget: 0,
                previouslyRunConfigManager: null,
                lastGlobalUpdate: null,
                viewUndo: false,
                numToUndo: 0,
                configurationsAvailableForUndo: [],
                configurationToBeUndone: [],
                undoAvailable: false,
                editingConfigurationId: 1,
                projectInfoDisplayed: false,
                save_access: false,
                current_open_project: null,
                current_open_project_id: 0,
                deleted_to_be_saved: [],
                changes_to_be_saved: false,
                reset_selected_configs: false,
                num_configurations_enabled: 10
            }
        },
        mounted() {
            this.dynamicComponent = this.CONFIG_INFO;
            this.configManager = new SweetSpotConfigurationManager();
            this.previouslyRunConfigManager = new SweetSpotConfigurationManager();
            let config_id = this.configManager.addNewRandomConfiguration(this.configurationTree);
            this.configList = this.configManager.getConfigurationList();
            this.editingConfigurationId = config_id;
            this.budget = this.inputBudget;
            this.runConfigurations([1]);
            this.save_access = this.$can_save.get_val();
        },
        watch: {
            globalUpdate: {
                handler(){
                    this.updateGlobalConfiguration(this.globalUpdate);
                    this.lastGlobalUpdate = this.globalUpdate;
                }
            },
            visibleBudgToSave: {
                handler(){
                    if(this.visibleBudgToSave.length > 0){
                        this.set_changes_to_be_saved(true);
                    }
                },
                immediate: true
            }
        },
        methods: {
            updateDrawerView: function(drawerView){
                switch(drawerView){
                    case 'CONFIG_EDIT':
                        this.dynamicComponent = this.CONFIG_EDIT;
                        this.$emit('widthUpdate','100vw');
                        break;
                    case 'CONFIG_INFO':
                        this.dynamicComponent = this.CONFIG_INFO;
                        if(this.configInfoDisplayed){
                            this.$emit('widthUpdate','100vw');
                        }
                        else {
                            this.$emit('widthUpdate', '50vw');
                        }
                        break;
                    case 'CONFIG_INFO_DISP':
                        if(this.configInfoDisplayed){
                            this.configInfoDisplayed = false;
                            this.$emit('widthUpdate','50vw');
                        }
                        else {
                            this.configInfoDisplayed = true;
                            this.$emit('widthUpdate', '100vw');
                        }
                        break;
                    case 'CONFIG_SAVE':
                        this.dynamicComponent = this.CONFIG_SAVE;
                        this.$emit('widthUpdate', '50vw');
                        break;
                    case 'CONFIG_SAVE_INFO':
                        this.dynamicComponent = this.CONFIG_SAVE;
                        if(this.projectInfoDisplayed){
                            this.projectInfoDisplayed = false;
                            this.$emit('widthUpdate', '100vw');
                        }
                        else { 
                            this.projectInfoDisplayed = true
                            this.$emit('widthUpdate', '100vw');
                        }
                        break;
                }
            },
            editConfiguration(id){
                this.updateDrawerView('CONFIG_EDIT');
                this.editingConfigurationId = id;
            },
            addConfig(){
                this.configManager.addNewRandomConfiguration(this.configurationTree);
                this.configList = this.configManager.getConfigurationList();
                if(this.lastGlobalUpdate != null){
                    this.updateGlobalConfiguration(this.lastGlobalUpdate);
                }
                this.set_changes_to_be_saved(true);
            },
            duplicateConfig(id){
                let configuration = this.configManager.getSweetSpotConfiguration(id);
                let configuration_name = configuration.get_configuration_name();
                this.configManager.duplicateConfiguration(configuration.get_configuration(), configuration_name);
                this.configList = this.configManager.getConfigurationList();
                this.set_changes_to_be_saved(true);
            },
            deleteConfigs(ids){
                this.previouslyRunConfigManager.deleteConfigurationList(ids);
                let file_ids = this.configManager.deleteConfigurationList(ids);
                for(let id of file_ids){
                    this.deleted_to_be_saved.push(id);
                }
                this.configList = this.configManager.getConfigurationList();
                this.$emit('deleteConfigurations',ids);
                this.checkUndoState();
                this.set_changes_to_be_saved(true);
            },
            runConfigurations(ids,visible_budgets=[]){
                let run_info = {};
                let config_ids_to_be_shown = [];
                let configs_to_be_run = {};
                let config_details = {};
                for(let id of ids){
                    let config = this.configManager.getSweetSpotConfiguration(id);
                    let config_name = config.get_configuration_name();
                    let visible_id = {'Name': config_name, 'Id': id};
                    config_ids_to_be_shown.push(visible_id);
                    config_details[id] = config;
                    if(this.previouslyRunConfigManager.isConfigurationIdinManager(id)) {
                        if(!this.previouslyRunConfigManager.isConfigurationEqual(id,config)) {
                            configs_to_be_run[id] = config;
                            this.previouslyRunConfigManager.replaceConfiguration(id,config.get_configuration());
                        }
                    }
                    else{
                        configs_to_be_run[id] = config;
                        this.previouslyRunConfigManager.addNewConfigurationFromConfigurationAt(config.get_configuration(),id);
                    }
                }
                run_info['run'] = configs_to_be_run;
                run_info['visible'] = config_ids_to_be_shown;
                run_info['config_details'] = config_details;
                if(visible_budgets.length > 0){
                    run_info['visible_budgets'] = visible_budgets;
                }
                this.$emit('runConfigurations',run_info);
            },
            updateConfiguration(value){
                let configId = value['Id'];
                this.configManager.updateConfiguration(configId,value['Field'],value['Value']);
                this.configList = this.configManager.getConfigurationList();
                this.set_changes_to_be_saved(true);
            },
            updateGlobalConfiguration(value){
                if(value != null) {
                    for (let cid in this.configList) {
                        let id = this.configList[cid]['id'];
                        let update = {'Id': id, 'Field': value['Field'], 'Value': value['Value']};
                        this.updateConfiguration(update);
                    }
                }
            },
            checkUndoState(){
                this.undoAvailable = this.configManager.recentlyDeletedExists();
            },
            undoDelete(){
                this.viewUndo = true;
                this.numToUndo = this.max_configurations_enabled - this.configList.length;
                this.configurationToBeUndone = [];
                this.configurationsAvailableForUndo = this.configManager.getRecentlyDeletedNameIdList();
            },
            verifyUndoSelection(){
                if((this.configList.length + this.configurationToBeUndone.length) > this.max_configurations_enabled){
                    this.configurationToBeUndone.pop();
                }
            },
            confirmUndo(){
              let file_ids = this.configManager.undoRecentlyDeletedFromIdList(this.configurationToBeUndone);
              this.previouslyRunConfigManager.undoRecentlyDeletedFromIdList(this.configurationToBeUndone);
              this.configList = this.configManager.getConfigurationList();
              this.$emit('undoDelete',this.configurationToBeUndone);
              this.viewUndo = false;
              this.configurationToBeUndone = [];
              this.checkUndoState();       
              for(let id of file_ids){
                if(!this.deleted_to_be_saved.includes(id[0])){
                    this.configManager.getSweetSpotConfiguration(id[1]).set_configuration_id(-1);
                    this.previouslyRunConfigManager.getSweetSpotConfiguration(id[1]).set_configuration_id(-1);
                }
              }
              this.set_changes_to_be_saved(true);
            },
            closeDataSelectorrDrawer(){
                this.$emit('closeScenarioClipboard')
            },
            set_changes_to_be_saved(val){
                if(val == true && this.current_open_project_id >= 0){
                    this.changes_to_be_saved = true;
                }
                else if(val == false){
                    this.changes_to_be_saved = false;
                }
            },
            projectOpened(project_name){
                this.current_open_project_id = project_name["Id"];
                this.current_open_project = project_name["Name"];
                this.set_changes_to_be_saved(false);
                this.deleted_to_be_saved = [];
                this.reset_selected_configs = true;
                let config_ids = this.previouslyRunConfigManager.getConfigurationIds();
                this.previouslyRunConfigManager.deleteConfigurationList(config_ids);
                this.configList = this.configManager.getConfigurationList();
                let c_ids = this.configManager.getConfigurationIds();
                this.runConfigurations(c_ids,project_name['VisibleBudgets']);
            },
            projectClosed(){
                this.current_open_project = null;
                this.current_open_project_id = 0;
                this.set_changes_to_be_saved(false);
                this.deleted_to_be_saved = [];
                this.reset_selected_configs = true;
                let config_id = this.configManager.addNewRandomConfiguration(this.configurationTree);
                this.configList = this.configManager.getConfigurationList();
                this.editingConfigurationId = config_id;
                this.budget = this.inputBudget;
                this.runConfigurations([1]);
            },
            saveProject: async function(){
                let files_to_be_added = [];
                let files_to_be_edited = [];
                let files_to_be_assigned = [];
                let idx = 0;
                for(let id of this.configList){
                    let config = this.configManager.getSweetSpotConfiguration(id['id']);
                    let config_id = config.get_configuration_id();
                    let config_name = config.get_configuration_name();
                    let configuration = config.get_configuration();
                    configuration['file_name'] = config_name;
                    if(this.visibleBudgToSave.length > 0){
                        configuration['visibleBudg'] = this.visibleBudgToSave[idx];
                    }
                    if(config_id == -1){
                        if(this.visibleBudgToSave.length == 0){
                            configuration['visibleBudg'] = 0;
                        }
                        files_to_be_added.push(configuration);
                        files_to_be_assigned.push(id['id']);
                    }
                    else{
                        configuration['file_id'] = config_id;
                        files_to_be_edited.push(configuration);
                    }
                    idx += 1;
                }
                let body = {};
                body['project_id'] = parseInt(this.current_open_project_id);
                body['files'] = files_to_be_added;
                if(files_to_be_added.length > 0){
                    await api_caller.save_new_files(body,this.$token.get_val()).then((response)=>{
                        let file_ids = response['files'];
                        for(let idx in files_to_be_assigned){
                            this.configManager.getSweetSpotConfiguration(files_to_be_assigned[idx]).set_configuration_id(file_ids[idx])
                        }
                    }).catch((err)=>{
                        console.error(err)
                    });
                }
                body['files'] = files_to_be_edited;
                if(files_to_be_edited.length > 0){
                    await api_caller.save_edited_files(body,this.$token.get_val()).catch((err)=>{
                        console.error(err)
                    });
                }
                body['files'] = this.deleted_to_be_saved;
                if(this.deleted_to_be_saved.length > 0){
                    await api_caller.save_deleted_files(body,this.$token.get_val()).then(()=>{
                        this.deleted_to_be_saved = [];
                    }).catch((err)=>{
                        console.error(err)
                    });
                }
                this.set_changes_to_be_saved(false);
            },
            saveNewProject: function(project_name){
                this.current_open_project_id = project_name["Id"];
                this.current_open_project = project_name["Name"];
                this.saveProject();
            }
        }
    }
</script>

<style scoped>

</style>