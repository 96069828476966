import '@mdi/font/css/materialdesignicons.css';
import {mdiNotebookEditOutline, mdiBookOpenPageVariantOutline, mdiNotebookPlusOutline, mdiFolderMultiplePlusOutline, mdiCog, mdiCogOutline} from '@mdi/js';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            edit_outline: mdiNotebookEditOutline,
            book_open_outline: mdiBookOpenPageVariantOutline,
            notebook_plus_outline: mdiNotebookPlusOutline,
            folder_multiple_plus_outline: mdiFolderMultiplePlusOutline,
            mdi_cog: mdiCog,
            mdi_cog_outline: mdiCogOutline,
        }
    },
    theme:{
        themes: {
            light: {
                primary: '#1B1B1B',
                secondary: '#FFFFFF',
                third: '#1B1B1B'
            },
        },
    },
});
