<template>
    <v-window show-arrows style="max-height: 100%; height: 100%; max-width: 100%;" elevation="6">
            <v-window-item style="max-height: 100%; height:100%; max-width: 100%;" v-if="loading">
                <v-sheet style="max-height: 100%; height: 100%; max-width: 100%; padding: 1%;" light>
                    <v-card style="max-height: 100%; height: 100%; max-width: 100%;" class="ma-0 pa-0">
                        <v-row class="pa-0 ma-0" justify="center" align="center" style="height: 100%; width: 100%;">
                            <v-col>
                                <v-progress-circular indeterminate color="primary" size="50"></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-sheet>
            </v-window-item>
            <v-window-item style="max-height: 100%; height:100%; max-width: 100%;" v-for="dataStat in dataStatistics" :key="dataStat.name" v-else>
                <v-sheet style="max-height: 100%; height: 100%; max-width: 100%; padding: 1%;"  elevation="12" rounded>
                    <v-card style="max-height: 100%; height: 100%; max-width: 100%;" class="ma-0 pa-0">
                        <v-row class="ma-0" style="height:20%; max-height: 20%; width: 100%; max-width: 100%;" justify="space-around" align="center">
                            <v-spacer></v-spacer>
                            <v-card flat style="height: 90%; width: 60%;">
                                <span class="highlightTitle">{{dataStat['Stats']['Name']}} - ${{formatNumber(dataStat['Stats']['Budget'])}}</span>
                            </v-card>
                            <v-card flat style="height: 90%; width: 20%;">
                                <v-tooltip bottom open-delay="500">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon @click="viewStatistics = !viewStatistics" color="primary" v-bind="attrs" v-on="on">mdi-rotate-3d-variant</v-icon>
                                    </template>
                                    <span class="help-tooltip">Flip Card</span>
                                </v-tooltip>
                            </v-card>
                        </v-row>
                        <v-row v-if="viewStatistics" class="ma-0" style="height:80%; max-height: 80%; width: 100%; max-width: 100%;" justify="space-around" align="center">
                            <v-card flat style="height: 35%; width: 30%;">
                                <span class="highlightTitle">Reach</span>
                                <v-divider class="ml-2 mr-2 highlightLine"></v-divider>
                                <p class="highlightData ma-2">{{formatNumber(dataStat['Stats']['OnTargetReach'])}}</p>
                            </v-card>
                            <v-card flat style="height: 35%; width: 30%;">
                                <span class="highlightTitle">Frequency</span>
                                <v-divider class="ml-2 mr-2 highlightLine"></v-divider>
                                <p class="highlightData ma-2">{{formatNumber(dataStat['Stats']['AvgFrequency'])}}</p>
                            </v-card>
                            <v-card flat style="height: 35%; width: 30%;">
                                <span class="highlightTitle">CPM</span>
                                <v-divider class="ml-2 mr-2 highlightLine"></v-divider>
                                <p class="highlightData ma-2">${{formatNumber(dataStat['Stats']['CPM'])}}</p>
                            </v-card>
                            <v-card flat style="height: 35%; width: 30%;">
                                <span class="highlightTitle">GRPs</span>
                                <v-divider class="ml-2 mr-2 highlightLine"></v-divider>
                                <p class="highlightData ma-2">{{formatNumber(dataStat['Stats']['GRPS'])}}</p>
                            </v-card>
                            <v-card flat style="height: 35%; width: 30%;">
                                <span class="highlightTitle">Impressions</span>
                                <v-divider class="ml-2 mr-2 highlightLine"></v-divider>
                                <p class="highlightData ma-2">{{formatNumber(dataStat['Stats']['Impressions'])}}</p>
                            </v-card>
                            <v-card flat style="height: 35%; width: 30%;">
                                <span class="highlightTitle">Target Pop.</span>
                                <v-divider class="ml-2 mr-2 highlightLine"></v-divider>
                                <p class="highlightData ma-2">{{formatNumber(dataStat['Stats']['TargetPop'])}}</p>
                            </v-card>
                        </v-row>
                        <v-row v-else-if="!viewStatistics" class="ma-0" style="height:80%; max-height: 80%; width: 100%; max-width: 100%;" justify="space-around" align="center">
                            <v-simple-table class="pa-0" style="max-height: 95%; max-width:95%; width: 90%; overflow-y: scroll" dense>
                                <thead>
                                    <tr>
                                        <th class="text-left configTitle">Scenario</th>
                                        <th class="text-left configTitle">Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="typeof dataStat['Config']['Market'] === 'string'">
                                        <td class="text-left configTitle">Market</td>
                                        <td class="text-left configData" >{{dataStat['Config']['Market']}}</td>
                                    </tr>
                                    <tr v-if="typeof dataStat['Config']['Product'] === 'string'">
                                        <td class="text-left configTitle">Product</td>
                                        <td class="text-left configData" >{{dataStat['Config']['Product']}}</td>
                                    </tr>
                                    <tr v-if="typeof dataStat['Config']['Product'] != 'string'">
                                        <td class="text-left configTitle">Product</td>
                                        <td class="text-left configData" v-for="prod in getFirstValue(dataStat['Config']['Product'])" :key="prod.id">{{prod}}</td>
                                    </tr>
                                    <tr v-for="prod in getAllButFirst(dataStat['Config']['Product'])" :key="prod.id">
                                        <td></td>
                                        <td class="text-left configData">{{prod}}</td>
                                    </tr>
                                    <!-- <tr>
                                        <td class="text-left configTitle">Number In Market</td>
                                        <td class="text-left configData">{{dataStat['Config']['NumberOf']}}</td>
                                    </tr> -->
                                    <tr v-if="Object.keys(dataStat['Config']).includes('Discount') && dataStat['Config']['Discount'] != 0">
                                        <td class="text-left configTitle">Discount</td>
                                        <td class="text-left configData">{{dataStat['Config']['Discount']}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left configTitle">Time In Market</td>
                                        <td class="text-left configData">{{getDifference(dataStat['Config']['StartDate'],dataStat['Config']['EndDate'])}} Weeks</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left configTitle">Start Date</td>
                                        <td class="text-left configData">{{dataStat['Config']['StartDate']}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left configTitle">End Date</td>
                                        <td class="text-left configData">{{dataStat['Config']['EndDate']}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left configTitle">Gender</td>
                                        <td class="text-left configData" v-for="gender in getFirstValue(dataStat['Config']['Gender'])" :key="gender.key">{{gender}}</td>
                                    </tr>
                                    <tr v-for="gender in getAllButFirst(dataStat['Config']['Gender'])" :key="gender.key">
                                        <td></td>
                                        <td class="text-left configData">{{gender}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left configTitle">Age Range</td>
                                        <td class="text-left configData" v-for="ageRange in getYoungestAge(dataStat['Config']['AgeRange'])" :key="ageRange.key">{{ageRange}}</td>
                                    </tr>
                                    <tr v-for="ageRange in getSortedAgeRemaining(dataStat['Config']['AgeRange'])" :key="ageRange.key">
                                        <td class="text-left"></td>
                                        <td class="text-left configData">{{ageRange}}</td>
                                    </tr>
                                    <tr v-if="typeof dataStat['Config']['Audience'] == 'string'">
                                        <td class="text-left configTitle">Behaviour</td>
                                        <td class="text-left configData">{{getAudienceCategory(dataStat['Config']['Audience'])}}</td>
                                    </tr>
                                    <tr v-if="typeof dataStat['Config']['Audience'] == 'string' && dataStat['Config']['Audience'] != 'None'">
                                        <td class="text-left"></td>
                                        <td class="text-left configData">{{getAudienceDescription(dataStat['Config']['Audience'])}}</td>
                                    </tr>
                                    <tr v-if="typeof dataStat['Config']['Audience'] == 'object'">
                                        <td class="text-left configTitle">Behaviour</td>
                                        <td class="text-left configData" v-for="prizm in getFirstValue(dataStat['Config']['Audience'])" :key="prizm.key">{{prizm.split('|Docma|')[1]}}</td>
                                    </tr>
                                    <tr v-for="prizm in getAllButFirst(dataStat['Config']['Audience'])" :key="prizm.key">
                                        <td></td>
                                        <td class="text-left configData">{{prizm.split('|Docma|')[1]}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-row>
                    </v-card>
                </v-sheet>
            </v-window-item>
    </v-window>
</template>

<script>
    export default {
        name: "Highlightsv2",
        props: [
            'dataManager',
            'budget',
            'loading',
            'runConfigurationsList'
        ],
        data() {
            return {
                dataStatistics: [],
                viewStatistics: true
            }
        },
        mounted(){
            this.update_data_statistics();
        },
        watch: {
            budget: {
                handler: function(){
                    this.update_data_statistics(this.budget);
                }
            },
            loading: {
                handler: function(){
                    if(this.loading == false){
                        this.update_data_statistics();
                    }
                }
            }
        },
        methods: {
            update_data_statistics(){
                let updatedDataStats = [];
                if(this.dataManager != null) {
                    updatedDataStats = this.dataManager.getAllDataStatisticsAtVisibleBudget();
                }
                this.dataStatistics = [];
                for(let dataStatId in updatedDataStats){
                    const config_filter = (element) => element['id'] == dataStatId;
                    const config_idx = this.runConfigurationsList.findIndex(config_filter);
                    let config = this.runConfigurationsList[config_idx]['config'].get_configuration();
                    let dataStat = {'Id': dataStatId, 'Stats': updatedDataStats[dataStatId], 'Config': config};
                    this.dataStatistics.push(dataStat);
                }
                if(this.dataStatistics.length == 0){
                    this.set_data_statistics_to_null();
                }
            },
            set_data_statistics_to_null(){
              let dataStat = {
                  'Id': -1,
                  'Stats': {
                      'OnTargetReach':0,'AvgFrequency':0,'TargetPop':0,'GRPS':0,'CPM':0, 'Budget': 0, 'Impressions': 0, 'Name': ''
                  },
                  'Config': {
                      'AgeRange': [],
                      'Audience': "None",
                      'Gender': [],
                      'Market': "N/A",
                      'NumberOf': 0,
                      'product': "N/A",
                      'StartDate': null,
                      'EndDate': null,
                      'TimeRange': '0 Weeks'
                  }
              };
              this.dataStatistics = [dataStat];
            },
            getFirstValue(arr){
                let tmpArr = [...arr];
                if(typeof tmpArr == 'object' && tmpArr != null && tmpArr.length > 0) {
                    if(tmpArr[0].split('|Docma|').length > 1 && tmpArr[0].split('|Docma|')[0] == 'HHI'){
                        tmpArr.sort(this.sortHHIList);
                    }
                    var first = tmpArr.slice(0, 1);
                    return first
                }
                return []
            },
            getAllButFirst(arr){
                if(typeof arr == 'object' && arr != null && arr.length > 1) {
                    let tmpArr = [...arr];
                    if(tmpArr[0].split('|Docma|').length > 1 && tmpArr[0].split('|Docma|')[0] == 'HHI'){
                        tmpArr.sort(this.sortHHIList);
                    }
                    var removed = tmpArr.slice(1, tmpArr.length);
                    return removed;
                }
                return []
            },
            sortHHIList(a,b){
                a = a.replaceAll('$','').replace('<','').replace('>','').replace(/,/g,'').replace('HHI|Docma|','');
                b = b.replaceAll('$','').replace('<','').replace('>','').replace(/,/g,'').replace('HHI|Docma|','');
                if (a.split('-').length == 1 && parseInt(a) == 35000){
                    return -1;
                }
                else if (a.split('-').length == 1 && parseInt(a) == 500000){
                    return 1;
                }
                if (b.split('-').length == 1 && parseInt(b) == 35000){
                    return 1;
                }
                else if (b.split('-').length == 1 && parseInt(b) == 500000){
                    return -1;
                }
                let a_greater = parseInt(a.split('-')[0]) < parseInt(b.split('-')[0]);
                let b_greater = parseInt(a.split('-')[0]) > parseInt(b.split('-')[0]);
                if(a_greater){
                    return -1;
                }
                if(b_greater){
                    return 1;
                }
                return 0;
            },
            getAudienceCategory(audience){
                if(audience != null && typeof audience == 'string'){
                    return audience.split('|Docma|')[0]
                }
                return ""
            },
            getAudienceDescription(audience){
                if(audience != null && typeof audience == 'string'){
                    return audience.split('|Docma|')[1]
                }
                return ""
            },
            getDifference(startDate, endDate) {
                let oneweek = 1000 * 60 * 60 * 24 * 7;
                let start = new Date(startDate);
                let end = new Date(endDate);
                let config_date_diff = ((end.getTime() - start.getTime()) / oneweek);
                return config_date_diff;
            },
            getYoungestAge(ageRange){
                if(ageRange != null && ageRange.length > 0) {
                    let tmp = ageRange.slice();
                    tmp.sort((a, b) => a.split('-')[0] - b.split('-')[0]);
                    if(tmp[0] == "5-14"){
                        return ["5-17"];
                    }
                    else if(tmp[0] == "15-24"){
                        return ["18-24"];
                    }
                    else {
                        return [tmp[0]];
                    }
                }
                return [];
            },
            getSortedAgeRemaining(ageRange){
                if(ageRange != null && ageRange.length > 1) {
                    let tmp = ageRange.slice();
                    tmp.sort((a, b) => a.split('-')[0] - b.split('-')[0]);
                    if(tmp[1] == "15-24"){
                        tmp[1] = "18-24";
                    }
                    return tmp.slice(1);
                }
                return [];
            },
            formatNumber(number){
                return number.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                });
            }
        }
    }
</script>

<style scoped>

</style>